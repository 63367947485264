.iframe-layout {
    padding-top: 50px;
    padding-bottom: 50px;
    @media @md-sm {
        padding-top: 0;
    }
    text-align: center;
    > * {
        text-align: start;
    }
    .iframe {
        display: inline-block;
        @media @lg {
            min-height: 4000px;
        }
        @media @md-sm {
            min-height: 600px;
            margin-bottom: 30px;
        }
        width: 100%;
        max-width: 935px;
    }

    .sidebar {
        padding-left: 40px;
        display: inline-block;
        max-width: 345px;
    }

    .destination-card {
        background: white;
        width: 100%;
        text-align: left;
        border-radius: 3px;
        padding: 10px;
        border: 1px solid @color-text;
        box-shadow: 0 0 4px fade(black, 25%);
        margin-bottom: 15px;
    }

    .card-img-container {
        position: relative;
        padding-top: 80%;
        overflow: hidden;
        .img {
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
        }
    }

    .card-content-container {
        padding-top: 10px;
        h3 {
            font-size: 16px;
            font-weight: bold;
        }
        h4 {
            font-size: 14px;
        }
    }

    .card-content-additional-info {
        border-top: 1px solid @color-text;
        .margin-horizontal(-10px);
        .padding-horizontal(10px);
        margin-top: 10px;
        padding-top: 10px;
        text-align: center;
        color: #4A90E2;
        font-weight: 300;
        .additional-info {
            margin-top: 0;
        }
        .promo {
            padding-top: 5px;
            float: right;
            font-size: 14px;
            color: black;
        }
    }

    .sidebar-block {
        padding-top: 20px;
        padding-bottom: 20px;
        &:first-of-type {
            padding-top: 0;
        }
        h2 {
            font-weight: bold;
            font-size: 21px;
            margin-bottom: 20px;
        }
    }

    .link-block {
        text-align: center;
        a {
            font-size: 16px;
            font-weight: 300;
            color: #4A90E2;
        }
    }

    @media (max-width: 1360px) {
        .iframe {
            margin-left: -20px;
            margin-right: -20px;
            width: 100vw;
        }

        .sidebar {
            padding-left: 0;
            max-width: none;
            display: block;
        }

        .sidebar-block {
            font-size: 0;
            margin: 0 -10px;
            @media (max-width: 1360px) {
                display: inline-block;
                padding-top: 0;
                margin-left: 0;
                margin-right: 0;
                width: 49%;
                vertical-align: top;
            }
            > * {
                font-size: 1rem;
            }
            > h2 {
                margin-left: 10px;
            }
            > a {
                @media (max-width: 1360px) {
                    padding: 0 10px;
                    width: 50%;
                    display: inline-block;
                }
                @media (max-width: 560px) {
                    width: 100%
                }
            }
        }
    }

    @media (max-width: 1085px) {
        .sidebar-block {
            display: block;
            width: 100%;
        }
    }
}
