.list {
    padding-top: 45px;
}

header.list-container {
    @media @md-sm {
        padding: 0;
    }
    @media @sm {
        .search-description-icon {
            display: none;
        }
    }
}

.search-description {
    position: relative;
    text-align: right;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 44px;
}

.search-description-icon {
    .absolutely-centered(@right: auto; @bottom: auto; @left: 90px);
    .size(150px, 210px);
    padding-top: 140px;
    font-size: 36px;
    text-align: center;
    font-weight: lighter;
    &::after {
        content: '';
        border-left: 75px solid transparent;
        border-right: 75px solid transparent;
        border-top: 20px solid transparent;
        position: absolute;
        bottom: -20px;
        width: 100%;
        height: 20px;
        left: 0;
    }
    &.accommodation {
        background: @color-accommodation url('@{img}sleeps.png') no-repeat center 25px;
        background-size: 100px;
        font-size: 18px;
        padding-top: 155px;
        font-weight: 400;
        &::after {
            border-top-color: @color-accommodation;
        }
    }
    &.excursions {
        padding-top: 150px;
        font-size: 28px;
        background: @color-excursions url('@{img}excursions.png') no-repeat center 40px;
        background-size: 100px;
        &::after {
            border-top-color: @color-excursions;
        }
    }
    &.guides {
        background: @color-guides url('@{img}guides.png') no-repeat center 25px;
        background-size: 100px;
        &::after {
            border-top-color: @color-guides;
        }
    }
}

.search-description-text {
    padding: 35px 0;
    text-align: center;
    display: block;
    position: relative;
    color: #9C9C9C;
    font: 300 20px @font-body;
    .panel {
        margin-top: 0;
    }
    .text-line {
        line-height: 150%;
    }
    strong {
        font-weight: 400;
        color: black;
    }
    button {
        .absolutely-centered(@top: 100%, @bottom: auto);
        width: 190px;
        border: 0;
        padding: 10px 20px;
        color: white;
        font: 300 20px @font-body;
        z-index: 3;
        outline: none;
        transition: 300ms;
        &.accommodation {
            background: @color-accommodation;
            //background: @gradient-accommodation;
        }
        &.excursions {
            background: @color-excursions; // IE9 fallback
            background: @gradient-excursions;
        }
        &.guides {
            background: @color-guides; // IE9 fallback
            background: @gradient-guides;
        }
        &.merged {
            background: transparent;
        }
    }
}

.additional-info {
    @info-color: #939393;
    margin-top: 20px;
    li {
        display: inline-block;
        font-size: 13px;
        color: @info-color;
        margin-left: 10px;
        &:first-of-type {
            margin-left: 0;
        }
        > a {
            height: 100%;
            width: 100%;
            display: block;
        }
        > a::before {
            content: '';
            display: inline-block;
            vertical-align: middle;
            height: 28px;
            width: 28px;
            border: 1px solid @info-color;
            border-radius: 50%;
            margin-right: 3px;
        }
        &.info > a::before {
            background: url('@{img}info-icon.png') no-repeat center center;
            background-size: 6px;
        }
        &.location > a::before {
            background: url('@{img}location-icon.png') no-repeat center center;
            background-size: 8px;
        }
    }
}

.result-list-accommodation {
    background: #F7F5F6;
    padding-bottom: 100px;
}

.result-panel {
    border-bottom: 1px solid @color-border;
    font-weight: 300;
    font-size: 14px;
    padding: 40px 0;
    //height: 260px; // TODO check
    &:first-of-type {
        border: 1px solid @color-border;
    }
    &:nth-of-type(odd) {
        background: #fff;
    }
    &:nth-of-type(even) {
        background: #F7F5F6;
    }
    h2 {
        font-size: 24px;
    }
    h3 {
        font-size: 14px;
        font-weight: 700;
    }
    .image {
        max-height: 200px;
        overflow: hidden;
        position: relative;
        top: 5px;
        //@media @lg {
            //padding-left: 20px;
        //}
        @media @md-sm {
            max-height: none;
        }
        a {
            display: block;
            width: 100%;
            @media @md-sm {
                position: relative;
                padding-top: 50%;
            }
        }
        img {
            width: 100%;
            @media @md-sm {
                position: absolute;
                bottom: 0;
                left: 0;
            }
        }
    }
    .content {
        position: relative;
        @media @lg {
            padding-left: 30px;
        }
        //    width: calc(100% - @image-box-width - @content-padding-left);
        //    padding-left: @content-padding-left;
    }
    .top {
        border-bottom: 1px dashed #979797;
        position: relative;
        @media @md-sm {
            padding-top: 30px;
        }
    }
    .name {
        border-right: 1px dashed #979797;
        padding: 0 20px 20px 0;
        @media @sm {
            border-right: none;
        }
    }
    .logo {
        position: relative;
        bottom: 10px;
        @media @lg-md {
            padding-left: 20px;
            padding-top: 20px;
        }
    }
    .price {
        font-size: 26px;
        @media @sm {
            font-size: 24px;
            padding-bottom: 10px;
        }
        //line-height: 19px;
    }
    .bottom {
        padding-top: 15px;
        position: relative;
        > * {
            @media @md-sm {
                .padding-vertical(20px);
            }
        }
    }
    .info {

    }
    .options {
        //padding-left: 30px;
        padding-right: 30px;
        line-height: 150%;
        font-size: 14px;
        @media @sm {
            padding-right: 0;
        }
    }
    .offer {
        padding-left: 30px;
        line-height: 150%;
        font-size: 14px;
    }
    .reservation {
        //position: absolute;
        //top: 15px;
        //right: 0;
        border: 0;
        color: white;
        border-radius: 3px;
        font-size: 14px;
        //line-height: 19px;
        background: @color-accommodation; // IE9 fallback
        background: @gradient-accommodation;
        width: 155px;
        height: 40px;
        display: inline-block;
        text-align: center;
        a {
            display: block;
            line-height: 40px;
            .size(100%);
        }
    }
}

.sorting-label {
    display: inline-block;
    vertical-align: middle;
    height: 100%;
    line-height: 50px;
    text-transform: uppercase;
    font-weight: bold;
}
.result-sorter {
    font-size: 0;
    height: 50px;
    color: #979797;
    > * {
        font-size: 1rem;
    }
    .sorter {
        padding-left: 20px;
        display: inline-block;
        background: #E8E7E7;
        border: 1px solid @color-border;
        border-bottom: 0;
        font-size: 14px;
        height: 100%;
        vertical-align: top;
        text-align: right;
        &::before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }
    .filter-results {
        display: inline-block;
        border-top: 1px solid @color-border;
        border-right: 1px solid @color-border;
        vertical-align: top;
        height: 100%;
        button {
            background: #E8E7E7;
            border: 0;
            font-weight: bold;
            padding-left: 20px;
            padding-right: 60px;
            height: 100%;
            font-size: 14px;
            color: @color-text;
            text-transform: uppercase;
            display: inline-block;
            cursor: pointer;
            position: relative;
            &:after {
                content: '';
                border-bottom: 2px solid @color-text;
                border-right: 2px solid @color-text;
                height: 15px;
                width: 15px;
                position: absolute;
                top: -8px;
                bottom: 0;
                margin: auto;
                right: 30px;
                transform: rotate(45deg);

            }
            &:hover {
                background: darken(#E8E7E7, 10%);
            }
        }
    }
    .select {
        position: relative;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        margin-left: 10px;
        padding-right: 10px;
        select {
            height: 100%;
        }
        &:first-of-type {
            margin-left: 25px;
        }
        &:last-of-type {
            margin-right: 25px;
        }
        &::after {
            content: '\25BC';
            font-size: 11px;
            .absolutely-centered(@top: 3px; @left: auto);
            height: 100%;
            .size(10px, 13px);
            z-index: 0;
        }
    }
}

.result-error {
    //margin: 100px 0 0;
    padding: 100px 0;
    background: white;
    border-top: 1px solid @color-border;
    border-bottom: 1px solid @color-border;
    h1 {
        font-size: 32px;
        font-weight: lighter;
        line-height: 150%;
    }
}

.list-form {
    background: @gradient-accommodation;
    @media (min-width: 809px) {
        width: 768px;
    }
    @media (max-width: 808px) {
        max-width: 768px;
    }
    position: absolute;
    display: block;
    z-index: 2;
    margin: auto;
    left: 0;
    right: 0;
    top: 100%;
    padding-top: 40px;
    border-radius: 4px;
    box-shadow: 0 2px 4px fade(black, 50%);
    &.ng-animate {
        transition: 400ms;
        transform-origin: top center;
        &.ng-hide-add {
            //opacity: 1;
            transform: scale(1);
        }
        &.ng-hide-add-active {
            //opacity: 0;
            transform: scale(0);
        }
        &.ng-hide-remove {
            //opacity: 0;
            transform: scale(0);
        }
        &.ng-hide-remove-active {
            //opacity: 1;
            transform: scale(1);
        }
    }
}

.close-form {
    position: absolute;
    right: 14px;
    top: 12px;
    color: white;
    height: 20px;
    width: 20px;
    &::before,
    &::after {
        content: '';
        height: 3px;
        width: 100%;
        position: absolute;
        background: whitesmoke;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    &::before {
        transform: rotate(45deg);
    }
    &::after {
        transform: rotate(-45deg);
    }
}

.change-search {
    color: @color-accommodation;
    font-weight: 300;
    font-size: 32px;
}