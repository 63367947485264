@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}
.clearfix {
  zoom: 1;
}
.clearfix::before,
.clearfix::after {
  content: '';
  display: table;
}
.clearfix::after {
  clear: both;
}
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}
.shell {
  max-width: 980px;
  height: 100%;
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 767px) {
  .shell {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.shell.bare {
  padding-left: 0;
  padding-right: 0;
}
.shell.wide {
  max-width: 1440px;
}
.shell.medium {
  max-width: 1220px;
}
.list-container {
  position: relative;
  max-width: 1320px;
  margin: auto;
  padding: 0 20px;
}
.animate-transition {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  transition: 400ms;
}
.animate-transition.ng-animate.fade-transition.ng-hide-add {
  opacity: 1;
}
.animate-transition.ng-animate.fade-transition.ng-hide-add-active {
  opacity: 0;
}
.animate-transition.ng-animate.fade-transition.ng-hide-remove {
  opacity: 0;
}
.animate-transition.ng-animate.fade-transition.ng-hide-remove-active {
  opacity: 1;
}
.animate-transition.ng-animate.slide-left-transition.ng-hide-add {
  opacity: 1;
  transform: translateX(0);
}
.animate-transition.ng-animate.slide-left-transition.ng-hide-add-active {
  opacity: 0;
  transform: translateX(-100%);
}
.animate-transition.ng-animate.slide-left-transition.ng-hide-remove {
  opacity: 0;
  transform: translateX(100%);
}
.animate-transition.ng-animate.slide-left-transition.ng-hide-remove-active {
  opacity: 1;
  transform: translateX(0);
}
.animate-transition.ng-animate.slide-up-transition.ng-hide-add {
  opacity: 1;
  transform: translateY(0);
}
.animate-transition.ng-animate.slide-up-transition.ng-hide-add-active {
  opacity: 0;
  transform: translateY(100%);
}
.animate-transition.ng-animate.slide-up-transition.ng-hide-remove {
  opacity: 0;
  transform: translateY(100%);
}
.animate-transition.ng-animate.slide-up-transition.ng-hide-remove-active {
  opacity: 1;
  transform: translateY(0);
}
.select {
  overflow-x: hidden;
}
.select select {
  outline: none;
  width: calc(100% + 25px);
  border: 0;
  background: transparent;
  font-size: inherit;
  color: inherit;
}
.select select:hover {
  cursor: pointer;
}
button {
  border: 0;
  background: transparent;
}
button:hover {
  cursor: pointer;
}
.accomodation-detail-content {
  margin-top: 40px;
}
@media (max-width: 39.9375rem) {
  .accommodation-detail-content-wrapper {
    margin: -20px;
    width: 100vw;
  }
}
.accomodation-info-top-wrapper {
  margin-left: auto;
  margin-right: auto;
}
.info-top-hotel-wrapper {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
  border-bottom: 1px dashed #999999;
}
.info-top-hotel {
  padding-right: 20px;
  padding-bottom: 20px;
  border-right: 1px dashed #999999;
}
.info-top-hotel h2 {
  font-size: 24px;
  margin-bottom: 5px;
}
.info-top-hotel p {
  font-size: 18px;
}
.accommodation-description-text {
  max-width: 60%;
  vertical-align: top;
  padding: 15px 35px 55px;
  text-align: center;
  position: relative;
  color: #9C9C9C;
  font: 100 20px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
.accommodation-description-text strong {
  font-weight: 400;
  color: black;
}
.accommodation-description-text a {
  display: block;
  font-size: 16px;
  color: #B40276;
  font-weight: 300;
  margin-top: 20px;
}
@media (max-width: 59.9375rem) {
  .accommodation-description-text {
    display: block;
    max-width: none;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
  }
}
.accomodation-detail-wrapper {
  margin-bottom: 30px;
  padding-bottom: 20px;
  background-color: #fff;
  border: 1px solid #999999;
}
.accomodation-detail-wrapper .accomodation-detail-unit {
  border-bottom: 1px dashed #999999;
}
.accomodation-detail-wrapper .accomodation-detail-unit .accomodation-detail-unit-left {
  padding: 1%;
  color: #666666;
}
.accomodation-detail-wrapper .accomodation-detail-unit .accomodation-detail-unit-left p {
  font-size: 1.1rem;
  line-height: 1.2em;
}
.accomodation-detail-wrapper .accomodation-detail-unit .accomodation-detail-unit-left p.first {
  font-size: 1.3rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 2%;
}
.accomodation-detail-wrapper .accomodation-detail-unit .accomodation-detail-unit-right {
  padding: 1%;
  text-align: center;
}
.accomodation-detail-wrapper .accomodation-detail-unit .accomodation-detail-unit-right p {
  font-size: 2rem;
  margin-bottom: 3%;
}
@media (min-width: 60rem) {
  .accomodation-detail-wrapper .accomodation-match-list-wrapper {
    padding: 40px;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .accomodation-detail-wrapper .accomodation-match-list-wrapper {
    padding: 30px;
  }
}
@media (max-width: 39.9375rem) {
  .accomodation-detail-wrapper .accomodation-match-list-wrapper {
    padding: 20px;
  }
}
.accommodation-detail-content {
  line-height: 150%;
}
@media (min-width: 60rem) {
  .accommodation-detail-content {
    padding: 50px;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .accommodation-detail-content {
    padding: 30px;
  }
}
@media (max-width: 39.9375rem) {
  .accommodation-detail-content {
    padding: 20px;
  }
}
.accomodation-detail-tabs {
  top: 0;
}
@media (min-width: 60rem) {
  .accomodation-detail-tabs li {
    margin-bottom: 5px;
  }
}
.accomodation-detail-tabs li a {
  color: #999999;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  padding: 15px 10px;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #FBFAFA;
  display: block;
  border: 1px solid #999999;
  border-radius: 0 4px 4px 0;
  position: relative;
}
@media (min-width: 60rem) {
  .accomodation-detail-tabs li a {
    left: -1px;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .accomodation-detail-tabs li a {
    border-left: 1px solid #999999;
    border-bottom: 1px solid #999999;
    border-radius: 4px 4px 0 0;
    position: relative;
    top: 1px;
    text-align: center;
  }
}
@media (max-width: 39.9375rem) {
  .accomodation-detail-tabs li a {
    text-align: center;
    border-radius: 0;
    border-top: none;
    border-bottom: none;
  }
  .accomodation-detail-tabs li a:first-of-type {
    border-top: 1px solid #999999;
  }
}
.accomodation-detail-tabs li .accomodation-tab-active {
  color: black;
  background-color: #ffffff;
}
@media (min-width: 60rem) {
  .accomodation-detail-tabs li .accomodation-tab-active {
    border-left: 1px solid transparent;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .accomodation-detail-tabs li .accomodation-tab-active {
    border-bottom: 1px solid transparent;
  }
}
.unit-name {
  font-size: 21px;
  font-weight: bold;
  margin-bottom: 30px;
}
.unit-name.overlaid {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 20px;
  color: white;
  background: rgba(0, 0, 0, 0.3);
  font-weight: 300;
  margin: 0;
  text-shadow: 0 0 3px #000;
  z-index: 1;
}
.unit-basic-info {
  position: relative;
}
.unit-basic-info li {
  line-height: 1.5em;
}
.unit-basic-info .unit-occupancy {
  margin-bottom: 0;
}
.unit-panel-info {
  padding: 30px;
  font-size: 14px;
}
.unit-panel-info-expanded {
  padding: 0 30px 0 0;
}
@media (max-width: 59.9375rem) {
  .unit-panel-info-expanded {
    padding-right: 0;
  }
}
.unit-details h3 {
  margin: 0 0 30px;
  font-weight: bold;
}
.unit-description {
  padding-right: 50px;
  line-height: 150%;
}
.unit-amenities-list {
  line-height: 150%;
  padding-right: 50px;
}
@media (max-width: 39.9375rem) {
  .unit-amenities-list {
    margin-top: 50px;
  }
}
.unit-packages {
  margin-bottom: 40px;
}
.unit-images {
  position: relative;
  z-index: 0;
  margin-bottom: 40px;
}
.unit-images img {
  width: 100%;
}
.unit-images .fancybox {
  display: none;
}
.unit-images .fancybox:first-child {
  display: block;
}
.unit-plan {
  border: 1px solid #979797;
  border-radius: 4px;
  padding: 30px;
  background: white;
  margin-top: 40px;
}
.unit-plan:first-of-type {
  margin-top: 0;
  border-top: 1px solid #979797;
}
.unit-plan .reservation {
  border: 0;
  max-width: 300px;
  color: white;
  border-radius: 3px;
  font-size: 14px;
  background: #B40276;
  background: linear-gradient(#cd0287, #9b0265);
}
.unit-plan .reservation a {
  display: block;
  cursor: pointer;
  width: 100%;
  padding: 15px 10px;
}
.unit-plan .policy-content {
  display: none;
}
.unit-plan .package-content {
  display: none;
}
.unit-plan .plan-policy-info {
  display: block;
  margin-top: 0.5em;
  font-size: 11px;
  color: #b40276;
}
.unit-plan .info-icon-grey {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
}
.unit-plan .info-icon-grey:after {
  position: absolute;
  top: 1px;
  content: '';
  width: 18px;
  height: 18px;
  display: block;
  background: url("../img/info-icon-grey.png") no-repeat center center;
}
.unit-plans-preview li {
  line-height: 200%;
  font-size: 14px;
}
.unit-panel-contracted:nth-of-type(even) {
  background-color: #f2f2f2;
}
.unit-panel-contracted:nth-of-type(odd) {
  background-color: #fff;
}
.unit-panel {
  margin: 50px 0;
}
.unit-panel:first-of-type {
  margin-top: 0;
}
.unit-panel-contracted {
  margin: 0;
  border: 1px solid #979797;
  border-top: none;
}
.unit-panel-contracted:first-of-type {
  border-top: 1px solid #979797;
}
.unit-panel-contracted .unit-plan {
  background: transparent;
  border-radius: 0;
  border: none;
  border-left: 1px dashed #979797;
}
@media (max-width: 39.9375rem) {
  .unit-panel-contracted .unit-plan {
    border-left: none;
  }
}
@media (min-width: 40rem) {
  .unit-panel-contracted .unit-plan.noavailability {
    height: 200px;
    padding-top: 75px;
  }
  .unit-panel-contracted .unit-plan .reservation {
    max-width: none;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .unit-panel-contracted .unit-plans {
    display: inline-block;
    width: 50%;
  }
  .unit-panel-contracted .unit-panel-info {
    display: inline-block;
    width: 50%;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .unit-panel-expanded .unit-plans {
    font-size: 0;
  }
  .unit-panel-expanded .unit-plans > * {
    font-size: 1rem;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .unit-panel-expanded .unit-plan {
    display: inline-block;
    width: 49%;
    margin-top: 20px;
  }
  .unit-panel-expanded .unit-plan:nth-of-type(2n + 1) {
    margin-right: 1%;
  }
  .unit-panel-expanded .unit-plan:nth-of-type(2n + 2) {
    margin-left: 1%;
  }
}
@media (max-width: 39.9375rem) {
  .unit-panel-expanded .unit-plan {
    padding: 0;
    border: none;
  }
}
@media (max-width: 59.9375rem) {
  .unit-panel-expanded .unit-occupancy {
    margin-top: 40px;
  }
}
.unit-panel-expanded ~ .unit-panel {
  border-top: 1px solid #979797;
  border-bottom: 0;
}
.unit-panel-expanded ~ .unit-panel:last-of-type {
  border-bottom: 1px solid #979797;
}
.plan-board {
  color: #B40276;
  font-weight: bold;
}
.plan-name {
  font-weight: bold;
  color: #b40276;
}
.plan-currency {
  font-size: 26px;
  margin: 0 0 0.5em 0;
}
.plan-price-type {
  display: inline-block;
  font-size: 13px;
  margin-top: 1em;
}
.accommodation-property-images {
  margin-top: 50px;
}
.accommodation-property-images li {
  padding: 20px;
  overflow: hidden;
  height: 200px;
}
.accommodation-property-images li img {
  width: 100%;
}
.unit-occupancy {
  margin-bottom: 40px;
}
.unit-occupancy li {
  font-size: 14px;
  line-height: 200%;
}
.accommodation-detail-map {
  max-height: 320px;
  overflow: hidden;
}
.accommodation-detail-map img {
  width: 100%;
}
.accommodation-detail-map-info {
  padding: 20px 40px;
}
.slide-animation.ng-animate {
  transition: 200ms height, 200ms opacity;
  overflow: hidden;
}
.slide-animation.ng-animate.ng-hide-add {
  height: 0;
}
.slide-animation.ng-animate.ng-hide-add-active {
  height: 0;
}
.slide-animation.ng-animate.ng-hide-remove {
  height: 0;
  opacity: 0;
}
.slide-animation.ng-animate.ng-hide-remove-active {
  height: 500px;
  opacity: 1;
}
.hotel-image-block {
  margin-bottom: 30px;
  position: relative;
  top: 10px;
}
.hotel-image-block img {
  width: 100%;
  margin-bottom: 10px;
}
.hotel-image-block .fancybox {
  display: none;
}
.hotel-image-block .fancybox:first-child {
  display: block;
}
.hotel-image-block i {
  cursor: pointer;
}
.hotel-image-block .gallery-count {
  top: 30px;
}
@media (min-width: 60rem) {
  .hotel-info-block {
    padding-left: 40px;
  }
}
.hotel-title-block,
.page-title-block {
  padding-bottom: 20px;
  line-height: 200%;
}
.hotel-title-block h2,
.page-title-block h2 {
  font-size: 30px;
  font-weight: bold;
}
.hotel-title-block {
  border-bottom: 1px dashed black;
}
.page-title-block {
  display: inline-block;
  padding: 0 0 0 0;
  margin: 0 0 20px 0;
  max-width: 30%;
  vertical-align: top;
}
@media (max-width: 59.9375rem) {
  .page-title-block {
    display: block;
    max-width: none;
  }
}
.page-title-block-info {
  border-right: 1px dashed #979797;
  display: inline-block;
  padding-right: 20px;
}
@media (max-width: 59.9375rem) {
  .page-title-block-info {
    padding-right: 0;
    border-right: none;
  }
}
.hotel-description-block h3 {
  font-weight: bold;
  margin: 35px 0 30px;
}
.hotel-map {
  width: 100%;
}
@media (min-width: 60rem) {
  .hotel-map {
    height: 50vw;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .hotel-map {
    height: 60vw;
  }
}
@media (max-width: 39.9375rem) {
  .hotel-map {
    height: 70vw;
  }
}
.detail-container {
  max-width: 1220px;
  margin: auto;
}
.fancybox-inner .policy-content {
  padding: 20px;
}
.fancybox-inner .policy-content UL LI {
  font-size: 14px;
  border-top: 1px solid #C2C1C1;
  padding: 10px 0;
}
.fancybox-inner .package-content {
  padding: 20px;
  box-sizing: border-box;
}
.fancybox-inner .package-content .package-image-slider {
  float: left;
  max-width: 425px;
  margin-right: 35px;
}
.fancybox-inner .package-content .package-image-slider .slider-item {
  width: 425px;
}
.fancybox-inner .package-content .package-image-slider .slider-item img {
  width: 100%;
}
.fancybox-inner .package-content H2 {
  font-size: 26px;
  line-height: 120%;
  margin-bottom: 50px;
}
.fancybox-inner .package-content H3 {
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 20px;
}
.fancybox-inner .package-content .package-basic-info {
  vertical-align: top;
}
.fancybox-inner .package-content .package-basic-info P {
  line-height: 25px;
}
.fancybox-inner .package-content .package-additional-info {
  padding-top: 30px;
}
.fancybox-inner .package-content .package-description {
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  width: 49%;
  padding-right: 30px;
}
.fancybox-inner .package-content .package-policy {
  vertical-align: top;
  display: inline-block;
  box-sizing: border-box;
  width: 49%;
  padding-left: 30px;
}
.fancybox-inner .package-content .package-policy UL LI {
  font-size: 14px;
  border-top: 1px solid #C2C1C1;
  padding: 10px 0;
}
.gallery-count {
  position: absolute;
  display: inline-block;
  top: 70px;
  left: 0;
  padding: 10px 15px 10px 40px;
  font-size: 14px;
  color: white;
  line-height: 100%;
  background: url("../img/gallery-icon.png") no-repeat 10px 49% #b40276;
}
.list {
  padding-top: 45px;
}
@media (max-width: 59.9375rem) {
  header.list-container {
    padding: 0;
  }
}
@media (max-width: 39.9375rem) {
  header.list-container .search-description-icon {
    display: none;
  }
}
.search-description {
  position: relative;
  text-align: right;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 44px;
}
.search-description-icon {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 90px;
  margin: auto;
  width: 150px;
  height: 210px;
  padding-top: 140px;
  font-size: 36px;
  text-align: center;
  font-weight: lighter;
}
.search-description-icon::after {
  content: '';
  border-left: 75px solid transparent;
  border-right: 75px solid transparent;
  border-top: 20px solid transparent;
  position: absolute;
  bottom: -20px;
  width: 100%;
  height: 20px;
  left: 0;
}
.search-description-icon.accommodation {
  background: #B40276 url('../img/sleeps.png') no-repeat center 25px;
  background-size: 100px;
  font-size: 18px;
  padding-top: 155px;
  font-weight: 400;
}
.search-description-icon.accommodation::after {
  border-top-color: #B40276;
}
.search-description-icon.excursions {
  padding-top: 150px;
  font-size: 28px;
  background: #00A553 url('../img/excursions.png') no-repeat center 40px;
  background-size: 100px;
}
.search-description-icon.excursions::after {
  border-top-color: #00A553;
}
.search-description-icon.guides {
  background: #039ADC url('../img/guides.png') no-repeat center 25px;
  background-size: 100px;
}
.search-description-icon.guides::after {
  border-top-color: #039ADC;
}
.search-description-text {
  padding: 35px 0;
  text-align: center;
  display: block;
  position: relative;
  color: #9C9C9C;
  font: 300 20px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
}
.search-description-text .panel {
  margin-top: 0;
}
.search-description-text .text-line {
  line-height: 150%;
}
.search-description-text strong {
  font-weight: 400;
  color: black;
}
.search-description-text button {
  position: absolute;
  top: 100%;
  right: 0;
  bottom: auto;
  left: 0;
  margin: auto;
  width: 190px;
  border: 0;
  padding: 10px 20px;
  color: white;
  font: 300 20px 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  z-index: 3;
  outline: none;
  transition: 300ms;
}
.search-description-text button.accommodation {
  background: #B40276;
}
.search-description-text button.excursions {
  background: #00A553;
  background: linear-gradient(#00bf60, #008c46);
}
.search-description-text button.guides {
  background: #039ADC;
  background: linear-gradient(#03acf5, #0388c3);
}
.search-description-text button.merged {
  background: transparent;
}
.additional-info {
  margin-top: 20px;
}
.additional-info li {
  display: inline-block;
  font-size: 13px;
  color: #939393;
  margin-left: 10px;
}
.additional-info li:first-of-type {
  margin-left: 0;
}
.additional-info li > a {
  height: 100%;
  width: 100%;
  display: block;
}
.additional-info li > a::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 28px;
  width: 28px;
  border: 1px solid #939393;
  border-radius: 50%;
  margin-right: 3px;
}
.additional-info li.info > a::before {
  background: url('../img/info-icon.png') no-repeat center center;
  background-size: 6px;
}
.additional-info li.location > a::before {
  background: url('../img/location-icon.png') no-repeat center center;
  background-size: 8px;
}
.result-list-accommodation {
  background: #F7F5F6;
  padding-bottom: 100px;
}
.result-panel {
  border-bottom: 1px solid #D8D8D8;
  font-weight: 300;
  font-size: 14px;
  padding: 40px 0;
}
.result-panel:first-of-type {
  border: 1px solid #D8D8D8;
}
.result-panel:nth-of-type(odd) {
  background: #fff;
}
.result-panel:nth-of-type(even) {
  background: #F7F5F6;
}
.result-panel h2 {
  font-size: 24px;
}
.result-panel h3 {
  font-size: 14px;
  font-weight: 700;
}
.result-panel .image {
  max-height: 200px;
  overflow: hidden;
  position: relative;
  top: 5px;
}
@media (max-width: 59.9375rem) {
  .result-panel .image {
    max-height: none;
  }
}
.result-panel .image a {
  display: block;
  width: 100%;
}
@media (max-width: 59.9375rem) {
  .result-panel .image a {
    position: relative;
    padding-top: 50%;
  }
}
.result-panel .image img {
  width: 100%;
}
@media (max-width: 59.9375rem) {
  .result-panel .image img {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.result-panel .content {
  position: relative;
}
@media (min-width: 60rem) {
  .result-panel .content {
    padding-left: 30px;
  }
}
.result-panel .top {
  border-bottom: 1px dashed #979797;
  position: relative;
}
@media (max-width: 59.9375rem) {
  .result-panel .top {
    padding-top: 30px;
  }
}
.result-panel .name {
  border-right: 1px dashed #979797;
  padding: 0 20px 20px 0;
}
@media (max-width: 39.9375rem) {
  .result-panel .name {
    border-right: none;
  }
}
.result-panel .logo {
  position: relative;
  bottom: 10px;
}
@media (min-width: 40rem) {
  .result-panel .logo {
    padding-left: 20px;
    padding-top: 20px;
  }
}
.result-panel .price {
  font-size: 26px;
}
@media (max-width: 39.9375rem) {
  .result-panel .price {
    font-size: 24px;
    padding-bottom: 10px;
  }
}
.result-panel .bottom {
  padding-top: 15px;
  position: relative;
}
@media (max-width: 59.9375rem) {
  .result-panel .bottom > * {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
.result-panel .options {
  padding-right: 30px;
  line-height: 150%;
  font-size: 14px;
}
@media (max-width: 39.9375rem) {
  .result-panel .options {
    padding-right: 0;
  }
}
.result-panel .offer {
  padding-left: 30px;
  line-height: 150%;
  font-size: 14px;
}
.result-panel .reservation {
  border: 0;
  color: white;
  border-radius: 3px;
  font-size: 14px;
  background: #B40276;
  background: linear-gradient(#cd0287, #9b0265);
  width: 155px;
  height: 40px;
  display: inline-block;
  text-align: center;
}
.result-panel .reservation a {
  display: block;
  line-height: 40px;
  width: 100%;
  height: 100%;
}
.sorting-label {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  line-height: 50px;
  text-transform: uppercase;
  font-weight: bold;
}
.result-sorter {
  font-size: 0;
  height: 50px;
  color: #979797;
}
.result-sorter > * {
  font-size: 1rem;
}
.result-sorter .sorter {
  padding-left: 20px;
  display: inline-block;
  background: #E8E7E7;
  border: 1px solid #D8D8D8;
  border-bottom: 0;
  font-size: 14px;
  height: 100%;
  vertical-align: top;
  text-align: right;
}
.result-sorter .sorter::before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.result-sorter .filter-results {
  display: inline-block;
  border-top: 1px solid #D8D8D8;
  border-right: 1px solid #D8D8D8;
  vertical-align: top;
  height: 100%;
}
.result-sorter .filter-results button {
  background: #E8E7E7;
  border: 0;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 60px;
  height: 100%;
  font-size: 14px;
  color: #979797;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
  position: relative;
}
.result-sorter .filter-results button:after {
  content: '';
  border-bottom: 2px solid #979797;
  border-right: 2px solid #979797;
  height: 15px;
  width: 15px;
  position: absolute;
  top: -8px;
  bottom: 0;
  margin: auto;
  right: 30px;
  transform: rotate(45deg);
}
.result-sorter .filter-results button:hover {
  background: #cfcdcd;
}
.result-sorter .select {
  position: relative;
  height: 100%;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 10px;
  padding-right: 10px;
}
.result-sorter .select select {
  height: 100%;
}
.result-sorter .select:first-of-type {
  margin-left: 25px;
}
.result-sorter .select:last-of-type {
  margin-right: 25px;
}
.result-sorter .select::after {
  content: '\25BC';
  font-size: 11px;
  position: absolute;
  top: 3px;
  right: 0;
  bottom: 0;
  left: auto;
  margin: auto;
  height: 100%;
  width: 10px;
  height: 13px;
  z-index: 0;
}
.result-error {
  padding: 100px 0;
  background: white;
  border-top: 1px solid #D8D8D8;
  border-bottom: 1px solid #D8D8D8;
}
.result-error h1 {
  font-size: 32px;
  font-weight: lighter;
  line-height: 150%;
}
.list-form {
  background: linear-gradient(#cd0287, #9b0265);
  position: absolute;
  display: block;
  z-index: 2;
  margin: auto;
  left: 0;
  right: 0;
  top: 100%;
  padding-top: 40px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
@media (min-width: 809px) {
  .list-form {
    width: 768px;
  }
}
@media (max-width: 808px) {
  .list-form {
    max-width: 768px;
  }
}
.list-form.ng-animate {
  transition: 400ms;
  transform-origin: top center;
}
.list-form.ng-animate.ng-hide-add {
  transform: scale(1);
}
.list-form.ng-animate.ng-hide-add-active {
  transform: scale(0);
}
.list-form.ng-animate.ng-hide-remove {
  transform: scale(0);
}
.list-form.ng-animate.ng-hide-remove-active {
  transform: scale(1);
}
.close-form {
  position: absolute;
  right: 14px;
  top: 12px;
  color: white;
  height: 20px;
  width: 20px;
}
.close-form::before,
.close-form::after {
  content: '';
  height: 3px;
  width: 100%;
  position: absolute;
  background: whitesmoke;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.close-form::before {
  transform: rotate(45deg);
}
.close-form::after {
  transform: rotate(-45deg);
}
.change-search {
  color: #B40276;
  font-weight: 300;
  font-size: 32px;
}
.animation-fade.ng-animate {
  transition: 300ms opacity;
}
.animation-fade.ng-animate.ng-hide-add {
  opacity: 1;
}
.animation-fade.ng-animate.ng-hide-add-active {
  opacity: 0;
}
.animation-fade.ng-animate.ng-hide-remove {
  opacity: 0;
}
.animation-fade.ng-animate.ng-hide-remove-active {
  opacity: 1;
}
.animation-scale-top.ng-animate {
  transition: 200ms ease-in;
  transform-origin: top center;
}
.animation-scale-top.ng-animate.ng-hide-add {
  transform: scaleY(1);
}
.animation-scale-top.ng-animate.ng-hide-add-active {
  transform: scaleY(0);
}
.animation-scale-top.ng-animate.ng-hide-remove {
  transform: scaleY(0);
}
.animation-scale-top.ng-animate.ng-hide-remove-active {
  transform: scaleY(1);
}
.contact-form-wrapper {
  display: inline-block;
  width: 73%;
  padding: 0px 50px 50px 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.contact-form-wrapper h2 {
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 0px;
  display: block;
  padding: 0px 0px 20px 0;
}
.contact-form-wrapper .form-field {
  display: block;
  margin-bottom: 10px;
}
.contact-form-wrapper .form-field label {
  width: 33%;
  display: inline-block;
  vertical-align: top;
}
.contact-form-wrapper .form-field > div {
  display: inline-block;
  width: 66%;
}
.contact-form-wrapper .form-field > div input,
.contact-form-wrapper .form-field > div textarea {
  width: 100%;
  padding: 4px 12px;
  margin-bottom: 0;
}
.contact-form-wrapper .form-field .form-field-captcha {
  position: relative;
  padding: 0 0 0 95px;
  box-sizing: border-box;
  -moze-box-sizing: border-box;
}
.contact-form-wrapper .form-field .form-field-captcha img {
  position: absolute;
  top: -3px;
  left: 0;
}
.contact-form-wrapper button[type="submit"] {
  padding: 10px 30px;
  color: #FFF;
  background-color: #00A553;
  font-size: 16px;
  border-radius: 3px;
}
.contact-form-wrapper button[type="submit"]:hover {
  opacity: 0.8;
}
.contact-form-wrapper .errorlist {
  width: 65%;
  margin-left: 34%;
  background: #eee;
  border: 1px solid #ccc;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 5px 20px;
  color: red;
  font-size: 12px;
  margin-bottom: 20px;
}
@media all and (max-width: 1360px) {
  .contact-form-wrapper {
    width: 100%;
    padding: 50px 0px;
  }
}
@media all and (max-width: 500px) {
  .contact-form-wrapper .form-field {
    margin-bottom: 15px;
  }
  .contact-form-wrapper .form-field label {
    width: 100%;
  }
  .contact-form-wrapper .form-field > div {
    width: 100%;
  }
}
.contact-form-sent-wrapper {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 80px 0 150px;
}
.contact-form-sent-wrapper h1 {
  font-size: 30px;
  margin-bottom: 30px;
}
.content-area {
  padding: 50px 0;
}
.content-area .content {
  max-width: 940px;
  margin: 0 auto;
}
.content-area P {
  font-size: 18px;
  margin-bottom: 1em;
  line-height: 150%;
}
.content-area H1 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 300;
  margin-bottom: 35px;
  text-align: center;
}
.content-area H2 {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 20px;
}
.content-area H3,
.content-area H4,
.content-area H5 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}
.content-area UL,
.content-area OL {
  margin-left: 40px;
  margin-bottom: 1em;
  line-height: 150%;
  font-size: 18px;
}
.content-area UL LI,
.content-area OL LI {
  padding: 5px 0;
  position: relative;
}
.content-area UL LI::before,
.content-area OL LI::before {
  content: ' - ';
  position: absolute;
  left: -20px;
}
.content-area.iframe-layout UL.destination-container-wrap {
  text-align: center;
  margin-left: inherit;
  margin-bottom: inherit;
  line-height: inherit;
  font-size: inherit;
}
.content-area.iframe-layout UL.destination-container-wrap LI {
  padding: 10px 15px;
}
.content-area.iframe-layout UL.destination-container-wrap LI::before {
  display: none;
}
.destination-container-wrap .card-content-container h3 {
  margin-bottom: 0;
}
.destination-list-title {
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 59.9375rem) {
  .destination-list-title {
    margin-top: 30px;
  }
}
.destination-container {
  padding: 10px 15px;
}
@media (min-width: 1600px) {
  .destination-container {
    width: 20%;
  }
}
@media (max-width: 499px) {
  .destination-container {
    width: 100%;
  }
}
.destination-detail-header {
  background: #6FADF4;
}
@media (max-width: 59.9375rem) {
  .destination-detail-header {
    margin-left: -20px;
    margin-right: -20px;
    width: 100vw;
  }
}
.destination-image-container {
  overflow: hidden;
}
.destination-image-container .img {
  height: 330px;
}
.destination-title-container {
  padding: 30px;
  position: relative;
}
@media (min-width: 60rem) {
  .destination-title-container {
    top: -40px;
  }
}
@media (max-width: 59.9375rem) {
  .destination-title-container {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.destination-name {
  font-weight: 100;
  font-size: 80px;
  color: white;
}
@media (max-width: 39.9375rem) {
  .destination-name {
    font-size: 48px;
  }
}
.about-destination {
  padding: 30px 0;
  font-size: 18px;
  line-height: 25px;
}
.about-destination h2 {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 20px;
}
.about-destination p {
  margin-bottom: 1em;
}
@media (max-width: 1084px) {
  .iframe-layout.destination-detail .sidebar {
    padding-left: 0;
    max-width: none;
    display: block;
  }
}
.iframe-layout.destination-detail .sidebar-block {
  font-size: 0;
}
@media (max-width: 1084px) and (min-width: 561px) {
  .iframe-layout.destination-detail .sidebar-block {
    margin: 0 -10px;
  }
}
.iframe-layout.destination-detail .sidebar-block > * {
  font-size: 1rem;
}
.iframe-layout.destination-detail .sidebar-block > h2 {
  margin-left: 10px;
}
@media (max-width: 1084px) {
  .iframe-layout.destination-detail .sidebar-block > a {
    padding: 0 10px;
    width: 25%;
    display: inline-block;
  }
}
@media (max-width: 935px) {
  .iframe-layout.destination-detail .sidebar-block > a {
    width: 50%;
  }
}
@media (max-width: 560px) {
  .iframe-layout.destination-detail .sidebar-block > a {
    width: 100%;
  }
}
.destination-detail {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
.destination-detail > * {
  text-align: start;
}
.destination-detail .destination-content {
  width: 100%;
  text-align: left;
}
@media (min-width: 1085px) {
  .destination-detail .destination-content {
    width: calc(100% - 350px);
  }
}
.destination-detail .destination-card {
  background: white;
  width: 100%;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #979797;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
}
.destination-detail .card-img-container {
  position: relative;
  padding-top: 80%;
  overflow: hidden;
}
.destination-detail .card-img-container .img {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.destination-detail .card-content-container {
  padding-top: 10px;
}
.destination-detail .card-content-container h3 {
  font-size: 16px;
  font-weight: bold;
}
.destination-detail .card-content-container h4 {
  font-size: 14px;
}
.destination-detail .card-content-additional-info {
  color: #4A90E2;
  font-weight: 300;
  text-align: center;
  border-top: 1px solid #979797;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  padding-top: 10px;
}
.destination-detail .card-content-additional-info .additional-info {
  margin-top: 0;
}
.destination-detail .card-content-additional-info .promo {
  padding-top: 5px;
  float: right;
  font-size: 14px;
  color: black;
}
.destination-detail .sidebar-block {
  padding-top: 20px;
  padding-bottom: 20px;
}
.destination-detail .sidebar-block:first-of-type {
  padding-top: 0;
}
.destination-detail .sidebar-block h2 {
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 20px;
}
.destination-detail .link-block {
  text-align: center;
}
.destination-detail .link-block a {
  font-size: 16px;
  font-weight: 300;
  color: #4A90E2;
}
.footer-info {
  padding: 45px 45px 65px;
  background: #F7F5F6;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.26);
}
@media (max-width: 59.9375rem) {
  .footer-info {
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer-info > * {
    padding-top: 10px;
    line-height: 15px;
  }
  .footer-info > *:first-child {
    padding-top: 0;
  }
}
.footer-info p {
  font-size: 9px;
}
.footer-extras {
  padding: 40px 80px 40px;
  font-size: 14px;
  color: #737373;
  border-top: 1px solid #979797;
  line-height: 22px;
  background: #FAFAFA;
}
@media (min-width: 960px) and (max-width: 1200px) {
  .footer-extras {
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 59.9375rem) {
  .footer-extras {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.footer-extras h3 {
  font-weight: 500;
  color: black;
  padding-bottom: 20px;
}
.footer-extras h4 {
  font-size: 11px;
  font-weight: bold;
  color: #737373;
}
.footer-extras ul {
  margin-bottom: 20px;
}
.footer-extras ul li {
  font-size: 11px;
}
.region {
  vertical-align: top;
  width: auto;
  padding-right: 40px;
}
.region h3 {
  padding-bottom: 0;
}
.region:last-child {
  padding-right: 0;
}
.region li {
  font-weight: 300;
}
.region li::before {
  content: " - ";
}
.form {
  font-size: 40px;
}
.form textarea {
  resize: vertical;
}
.form input::-webkit-input-placeholder,
.form textarea::-webkit-input-placeholder {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: rgba(0, 0, 0, 0.5);
}
.form input::-moz-placeholder,
.form textarea::-moz-placeholder {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: rgba(0, 0, 0, 0.5);
}
.form input:-moz-placeholder,
.form textarea:-moz-placeholder {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: rgba(0, 0, 0, 0.5);
}
.form input:-ms-input-placeholder,
.form textarea:-ms-input-placeholder {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
  color: rgba(0, 0, 0, 0.5);
}
.form input,
.form textarea,
.form select {
  padding: 0.5em;
  font-size: 1rem;
}
.form input,
.form textarea,
.form .select {
  background: #fff;
  border: 1px solid black;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
}
.form input[type='checkbox'],
.form input[type='radio'] {
  height: 1em;
  width: 1em;
  margin-right: 0.5em;
  display: inline-block;
  vertical-align: middle;
  overflow-x: hidden;
}
.form input[type='radio'] {
  border-radius: 50%;
  position: relative;
}
.form input[type='radio']::before {
  border-radius: 50%;
}
.form input[type='radio']:checked::before {
  background: radial-gradient(black 50%, white 50%);
}
.checkbox {
  display: inline-block;
  vertical-align: middle;
  overflow-x: hidden;
  position: relative;
}
.checkbox label {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.checkbox label:hover {
  cursor: pointer;
}
.checkbox label::before {
  content: '';
  margin-right: 0.5em;
  height: 1.2em;
  width: 1.2em;
  background: white;
  border: 1px solid black;
  display: inline-block;
  vertical-align: middle;
  line-height: 1.2em;
  font-size: 1.2em;
  text-align: center;
  position: relative;
  top: -0.1em;
}
.checkbox input[type="checkbox"] {
  display: none;
}
.checkbox input[type="checkbox"]:checked + label::before {
  background-image: url('../img/check.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 15px;
}
.radio::before {
  border-radius: 50%;
}
.select {
  overflow-x: hidden;
  position: relative;
}
.select select {
  background: transparent;
  border: 0;
  width: calc(100% + 20px + 1em);
  z-index: 1;
  position: relative;
  height: 2rem;
  padding: 0.5em;
  -webkit-appearance: none;
}
.checkboxes > * {
  padding: 10px 0;
}
.pickadate-container {
  position: relative;
  display: inline-block;
  vertical-align: inherit;
}
.input-autocomplete {
  position: relative;
}
.input-autocomplete .loader {
  padding-right: 30px;
}
.input-suggestions {
  position: absolute;
  left: 0;
  right: 10px;
  top: 100%;
  background: white;
  color: black;
  z-index: 20;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border: 1px solid white;
  text-align: left;
}
.input-suggestions li {
  padding: 10px;
  font-size: 14px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transition: 200ms background-color;
}
.input-suggestions li:first-of-type {
  border-top: none;
}
.input-suggestions li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}
.location-querying {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  height: 1em;
  width: 1em;
  border-radius: 50%;
  border: 3px solid white;
  border-top: 3px solid transparent;
  animation: loading 1s ease-in-out infinite;
}
.select .pseudo-select {
  display: none;
  width: 100%;
}
.select .select2-container .select2-selection--single {
  color: white;
  background: transparent;
  height: 40px;
  line-height: 40px;
  border: none;
  outline: none;
}
.select .select2-container .select2-selection--single .select2-selection__arrow {
  display: none;
}
.select .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 38px;
  color: white;
  font-size: 14px;
  text-align: left;
  font-weight: 400;
}
.select.wizard .select2-container--default .select2-selection--single .select2-selection__rendered {
  color: black;
}
.select .select2-container .select2-selection__placeholder {
  color: white;
  font-weight: 400;
}
.select.wizard .select2-container .select2-selection__placeholder {
  color: black;
}
.select2-dropdown {
  background-color: #F6F6F6;
}
.select2-container--open .select2-dropdown--below {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
  left: -5px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  padding: 5px;
  font-size: 14px;
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #B40276;
}
.select2-results__option {
  font-size: 14px;
  padding-left: 15px;
}
.select2-results__option[id*='all'],
.select2-results__option[id*='region'] {
  font-weight: 700;
}
.select2-results__option[id*='zip'] {
  padding-left: 35px;
}
@media (min-width: 40rem) {
  .valign-top {
    vertical-align: top;
  }
}
@media (max-width: 59.9375rem) {
  .valign-top-md-sm {
    vertical-align: top;
  }
}
@media (min-width: 60rem) {
  .valign-top-lg {
    vertical-align: top;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .valign-top-md {
    vertical-align: top;
  }
}
@media (max-width: 39.9375rem) {
  .valign-top-sm {
    vertical-align: top;
  }
}
@media (min-width: 40rem) {
  .valign-middle {
    vertical-align: middle;
  }
}
@media (max-width: 59.9375rem) {
  .valign-middle-md-sm {
    vertical-align: middle;
  }
}
@media (min-width: 60rem) {
  .valign-middle-lg {
    vertical-align: middle;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .valign-middle-md {
    vertical-align: middle;
  }
}
@media (max-width: 39.9375rem) {
  .valign-middle-sm {
    vertical-align: middle;
  }
}
@media (min-width: 40rem) {
  .valign-bottom {
    vertical-align: bottom;
  }
}
@media (max-width: 59.9375rem) {
  .valign-bottom-md-sm {
    vertical-align: bottom;
  }
}
@media (min-width: 60rem) {
  .valign-bottom-lg {
    vertical-align: bottom;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .valign-bottom-md {
    vertical-align: bottom;
  }
}
@media (max-width: 39.9375rem) {
  .valign-bottom-sm {
    vertical-align: bottom;
  }
}
@media (min-width: 40rem) {
  .text-left {
    text-align: left;
  }
}
@media (max-width: 59.9375rem) {
  .text-left-md-sm {
    text-align: left;
  }
}
@media (min-width: 60rem) {
  .text-left-lg {
    text-align: left;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .text-left-md {
    text-align: left;
  }
}
@media (max-width: 39.9375rem) {
  .text-left-sm {
    text-align: left;
  }
}
@media (min-width: 40rem) {
  .text-center {
    text-align: center;
  }
}
@media (max-width: 59.9375rem) {
  .text-center-md-sm {
    text-align: center;
  }
}
@media (min-width: 60rem) {
  .text-center-lg {
    text-align: center;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .text-center-md {
    text-align: center;
  }
}
@media (max-width: 39.9375rem) {
  .text-center-sm {
    text-align: center;
  }
}
@media (min-width: 40rem) {
  .text-right {
    text-align: right;
  }
}
@media (max-width: 59.9375rem) {
  .text-right-md-sm {
    text-align: right;
  }
}
@media (min-width: 60rem) {
  .text-right-lg {
    text-align: right;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .text-right-md {
    text-align: right;
  }
}
@media (max-width: 39.9375rem) {
  .text-right-sm {
    text-align: right;
  }
}
@media (min-width: 40rem) {
  .push {
    float: right;
  }
}
@media (max-width: 59.9375rem) {
  .push-md-sm {
    float: right;
  }
}
@media (min-width: 60rem) {
  .push-lg {
    float: right;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .push-md {
    float: right;
  }
}
@media (max-width: 39.9375rem) {
  .push-sm {
    float: right;
  }
}
@media (min-width: 40rem) {
  .pull {
    float: left;
  }
}
@media (max-width: 59.9375rem) {
  .pull-md-sm {
    float: left;
  }
}
@media (min-width: 60rem) {
  .pull-lg {
    float: left;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .pull-md {
    float: left;
  }
}
@media (max-width: 39.9375rem) {
  .pull-sm {
    float: left;
  }
}
@media (min-width: 40rem) {
  .hidden {
    display: none;
  }
}
@media (max-width: 59.9375rem) {
  .hidden-md-sm {
    display: none;
  }
}
@media (min-width: 60rem) {
  .hidden-lg {
    display: none;
  }
}
@media (min-width: 40rem) and (max-width: 59.9375rem) {
  .hidden-md {
    display: none;
  }
}
@media (max-width: 39.9375rem) {
  .hidden-sm {
    display: none;
  }
}
.home-title {
  text-align: center;
  font-weight: 300;
  padding: 60px 0;
}
.home-title h2 {
  font-size: 46px;
  line-height: 56px;
  color: #ED6806;
  margin-bottom: 20px;
}
.home-title p {
  font-size: 24px;
  line-height: 29px;
  color: #9C9C9C;
}
.panel {
  margin-top: 55px;
}
.panel:first-of-type {
  margin-top: 0;
}
.panel h2 {
  font-size: 28px;
  line-height: 44px;
  color: white;
  font-weight: 300;
  text-align: center;
  position: relative;
}
.panel .panel-icon {
  position: relative;
  padding-top: 130px;
  padding-bottom: 30px;
  text-align: center;
}
@media (min-width: 40rem) {
  .panel .expand-mobile-panel {
    display: none;
  }
}
@media (max-width: 39.9375rem) {
  .panel .expand-mobile-panel {
    width: auto;
    font-size: 16px;
    border-radius: 4px;
    vertical-align: middle;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.15);
    color: white;
    font-weight: 700;
    padding: 0.3em 1em;
    margin-top: 30px;
  }
  .panel .expand-mobile-panel:hover {
    cursor: pointer;
  }
}
.panel .panel-form {
  height: 100%;
  color: white;
}
@media (max-width: 59.9375rem) {
  .panel .panel-form {
    padding-bottom: 30px;
  }
}
.panel .panel-promo-box {
  height: 240px;
  padding: 20px 10px;
  text-align: right;
}
.panel.excursions {
  background: #00A553;
  background: linear-gradient(#00bf60, #008c46);
}
.panel.excursions .panel-icon {
  background: url('../img/izleti.png') no-repeat center 25px;
  background-size: 80px;
}
.panel.excursions .panel-promo-box {
  background: url('../img/excursions-promo.jpg') no-repeat center center;
  background-size: cover;
}
.panel.guides {
  background: #039ADC;
  background: linear-gradient(#03acf5, #0388c3);
}
.panel.guides .panel-icon {
  background: url('../img/vodic.png') no-repeat center 25px;
  background-size: 80px;
}
.panel.guides .panel-promo-box {
  background: url('../img/guides-promo.jpg') no-repeat center center;
  background-size: cover;
}
.panel.accommodation {
  background: #B40276;
  background: linear-gradient(#cd0287, #9b0265);
}
.panel.accommodation .panel-icon {
  background: url('../img/sleeps-light.png') no-repeat center 25px;
  background-size: 95px;
}
.panel.accommodation .panel-promo-box {
  background: url('../img/accommodation-promo.jpg') no-repeat center center;
  background-size: cover;
}
.panel.rent-a-car {
  background: #F28331;
  background: linear-gradient(#f49149, #f07519);
}
.panel.rent-a-car .panel-icon {
  background: url('../img/rentacar.png') no-repeat center 35px;
  background-size: 80px;
}
.panel.rent-a-car .panel-promo-box {
  background: url('../img/rent-a-car-promo.jpg') no-repeat center center;
  background-size: cover;
}
.panel input {
  display: inline-block;
  vertical-align: middle;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.15);
  outline: none;
  border-radius: 4px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
}
.panel input[type=submit] {
  width: auto;
  font-size: 16px;
  border-radius: 4px;
  vertical-align: middle;
  border: 1px solid rgba(255, 255, 255, 0.5);
  background: rgba(255, 255, 255, 0.15);
  color: white;
  font-weight: 700;
  padding: 0 20px;
}
.panel input[type=submit]:hover {
  cursor: pointer;
}
.panel .checkbox {
  padding: 10px 12px;
  font-size: 14px;
}
.panel .checkbox label::before {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: white;
  border-radius: 4px;
  width: 22px;
  height: 22px;
}
.panel .checkbox label:hover {
  cursor: pointer;
}
.panel .select {
  display: inline-block;
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.5);
  overflow: hidden;
  height: 40px;
  width: 100%;
  border-radius: 4px;
  vertical-align: middle;
  padding-left: 5px;
}
.panel .select select {
  background: transparent;
  border: 0;
  outline: none;
  height: 38px;
  color: white;
}
.panel .select select option {
  color: black;
}
.panel select,
.panel input {
  font-size: 14px;
  color: white;
}
.panel select::-webkit-input-placeholder,
.panel input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  color: white;
}
.panel select::-moz-placeholder,
.panel input::-moz-placeholder {
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  color: white;
}
.panel select:-moz-placeholder,
.panel input:-moz-placeholder {
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  color: white;
}
.panel select:-ms-input-placeholder,
.panel input:-ms-input-placeholder {
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  color: white;
}
.destinations {
  padding-top: 55px;
  padding-bottom: 55px;
  text-align: center;
}
.destinations h2 {
  font-size: 36px;
  line-height: 44px;
  font-weight: 300;
  margin-bottom: 35px;
}
.destinations .home-slider {
  position: relative;
}
.destinations .home-slider .slider-item {
  background: #fff;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #979797;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
}
.destinations .home-slider .slider-item .card-content-container {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding-top: 10px;
}
.destinations .home-slider .slider-item A {
  color: black;
}
@media screen and (max-width: 767px) {
  .destinations .home-slider .slider-item {
    width: 44%;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 480px) {
  .destinations .home-slider .slider-item {
    width: 100%;
  }
}
.destinations .home-slider .slider-img-container {
  position: relative;
  overflow: hidden;
  padding-top: 100%;
}
.destinations .home-slider .slider-img-container .img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.destinations .home-slider .bx-prev,
.destinations .home-slider .bx-next {
  display: block;
  cursor: pointer;
  width: 80px;
  height: 80px;
  transition: 100ms;
  z-index: 10;
}
.destinations .home-slider .bx-prev:hover,
.destinations .home-slider .bx-next:hover {
  cursor: pointer;
}
.destinations .home-slider .bx-prev:active,
.destinations .home-slider .bx-next:active {
  transform: scale(0.95);
}
.destinations .home-slider .bx-prev {
  position: absolute;
  top: 0;
  right: auto;
  bottom: 0;
  left: -50px;
  margin: auto;
  background: url('../img/left.png') no-repeat center center;
  background-size: contain;
}
.destinations .home-slider .bx-next {
  position: absolute;
  top: 0;
  right: -50px;
  bottom: 0;
  left: auto;
  margin: auto;
  background: url('../img/right.png') no-repeat center center;
  background-size: contain;
}
.destinations > p {
  font-size: 18px;
  line-height: 22px;
  font-weight: 300;
}
.destinations > p:first-of-type {
  margin-top: 35px;
}
.destinations a {
  color: #4A90E2;
}
.select-indicator {
  position: relative;
}
.select-indicator::before,
.select-indicator::after {
  content: '';
  position: absolute;
  display: block;
  left: auto;
  right: 12px;
  margin: auto;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}
.select-indicator::before {
  top: 55%;
  border-top: 6px solid rgba(255, 255, 255, 0.5);
}
.select-indicator::after {
  bottom: 55%;
  border-bottom: 6px solid rgba(255, 255, 255, 0.5);
}
.panel-form {
  padding: 15px 35px;
}
@media (max-width: 39.9375rem) {
  .panel-form {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.panel-form .grid > [class*="col-"] {
  padding: 10px;
}
@media (max-width: 39.9375rem) {
  .panel-form.home-form {
    display: none;
  }
}
.picker__holder {
  min-width: 320px;
  left: 0;
}
.from-to-price,
.see-more {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 14px;
}
.from-to-price {
  background: rgba(255, 255, 255, 0.7);
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
}
.from-to-price > * {
  vertical-align: middle;
}
.from-to-price strong {
  font-size: 18px;
  margin-right: 3px;
}
.see-more {
  color: white;
  padding: 10px 20px;
}
.see-more.excursions {
  background: #00A553;
}
.see-more.guides {
  background: #039ADC;
}
.see-more.accommodation {
  background: #B40276;
}
.see-more.rent-a-car {
  background: #F28331;
}
.panel input.submit-button {
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.64);
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  height: 45px;
  font-weight: 400;
}
.panel input.excursion-button {
  background: 150.18181818;
  background: linear-gradient(#71D726, #42B632);
  padding-left: 2.5em;
  padding-right: 2.5em;
}
.panel input.guides-button {
  background: 198.24884793;
  background: linear-gradient(#69C8F2, #35AADD);
  padding-left: 2.5em;
  padding-right: 2.5em;
}
.panel input.accommodation-button {
  background: #B40276;
  background: linear-gradient(#D2599D, #B22F78);
}
.form-fields {
  margin-bottom: 20px;
}
.panel .form-dropdown {
  overflow: visible;
  padding-left: 0;
  text-align: left;
  font-weight: 400;
}
.panel .form-dropdown > a {
  display: block;
  height: 100%;
  line-height: 38px;
  padding-left: 10px;
  font-size: 14px;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}
.checkboxes-dropdown {
  position: absolute;
  z-index: 3;
  background: #F6F6F6;
  color: #4d4d4d;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 100%;
  min-width: 200px;
  height: auto;
  left: 0;
  top: 100%;
}
.checkboxes-dropdown li {
  display: block;
}
.panel .checkboxes-dropdown .checkbox label::before {
  border-color: #4d4d4d;
}
.panel .checkboxes-dropdown .checkbox input[type=checkbox]:checked + label::before {
  background-image: url('../img/check-dark.png');
}
.destination-gallery {
  padding: 0 50px;
  max-width: 1540px;
  margin: auto;
}
.panel-showcase {
  margin: -20px -10px;
  height: 240px;
  padding: 20px 10px;
  position: relative;
}
.panel-showcase-info {
  background: rgba(255, 255, 255, 0.7);
  padding: 20px;
  font-size: 22px;
  margin: -20px -10px 20px;
}
.panel-showcase-info h1 {
  font-weight: bold;
}
.showcase-see-more {
  position: absolute;
  bottom: 20px;
  left: 20px;
}
.beta-badge {
  display: inline-block;
  float: left;
  margin-top: 10px;
  margin-left: 5px;
  background-color: #60BE02;
  border-radius: 5px;
  color: white;
  text-transform: lowercase;
  font-size: small;
  font-weight: 400;
  padding: 1px 5px;
}
.iframe-layout {
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}
@media (max-width: 59.9375rem) {
  .iframe-layout {
    padding-top: 0;
  }
}
.iframe-layout > * {
  text-align: start;
}
.iframe-layout .iframe {
  display: inline-block;
  width: 100%;
  max-width: 935px;
}
@media (min-width: 60rem) {
  .iframe-layout .iframe {
    min-height: 4000px;
  }
}
@media (max-width: 59.9375rem) {
  .iframe-layout .iframe {
    min-height: 600px;
    margin-bottom: 30px;
  }
}
.iframe-layout .sidebar {
  padding-left: 40px;
  display: inline-block;
  max-width: 345px;
}
.iframe-layout .destination-card {
  background: white;
  width: 100%;
  text-align: left;
  border-radius: 3px;
  padding: 10px;
  border: 1px solid #979797;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 15px;
}
.iframe-layout .card-img-container {
  position: relative;
  padding-top: 80%;
  overflow: hidden;
}
.iframe-layout .card-img-container .img {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}
.iframe-layout .card-content-container {
  padding-top: 10px;
}
.iframe-layout .card-content-container h3 {
  font-size: 16px;
  font-weight: bold;
}
.iframe-layout .card-content-container h4 {
  font-size: 14px;
}
.iframe-layout .card-content-additional-info {
  border-top: 1px solid #979797;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 10px;
  padding-top: 10px;
  text-align: center;
  color: #4A90E2;
  font-weight: 300;
}
.iframe-layout .card-content-additional-info .additional-info {
  margin-top: 0;
}
.iframe-layout .card-content-additional-info .promo {
  padding-top: 5px;
  float: right;
  font-size: 14px;
  color: black;
}
.iframe-layout .sidebar-block {
  padding-top: 20px;
  padding-bottom: 20px;
}
.iframe-layout .sidebar-block:first-of-type {
  padding-top: 0;
}
.iframe-layout .sidebar-block h2 {
  font-weight: bold;
  font-size: 21px;
  margin-bottom: 20px;
}
.iframe-layout .link-block {
  text-align: center;
}
.iframe-layout .link-block a {
  font-size: 16px;
  font-weight: 300;
  color: #4A90E2;
}
@media (max-width: 1360px) {
  .iframe-layout .iframe {
    margin-left: -20px;
    margin-right: -20px;
    width: 100vw;
  }
  .iframe-layout .sidebar {
    padding-left: 0;
    max-width: none;
    display: block;
  }
  .iframe-layout .sidebar-block {
    font-size: 0;
    margin: 0 -10px;
  }
  .iframe-layout .sidebar-block > * {
    font-size: 1rem;
  }
  .iframe-layout .sidebar-block > h2 {
    margin-left: 10px;
  }
}
@media (max-width: 1360px) and (max-width: 1360px) {
  .iframe-layout .sidebar-block {
    display: inline-block;
    padding-top: 0;
    margin-left: 0;
    margin-right: 0;
    width: 49%;
    vertical-align: top;
  }
}
@media (max-width: 1360px) and (max-width: 1360px) {
  .iframe-layout .sidebar-block > a {
    padding: 0 10px;
    width: 50%;
    display: inline-block;
  }
}
@media (max-width: 1360px) and (max-width: 560px) {
  .iframe-layout .sidebar-block > a {
    width: 100%;
  }
}
@media (max-width: 1085px) {
  .iframe-layout .sidebar-block {
    display: block;
    width: 100%;
  }
}
.main-nav {
  font-weight: 300;
  border-bottom: 1px solid #979797;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding-left: 15px;
  z-index: 40;
  text-align: center;
  height: 51px;
  width: 100%;
  background: white;
  position: relative;
}
@media (min-width: 1100px) {
  .main-nav {
    padding-right: 280px;
  }
}
@media (max-width: 1099px) {
  .main-nav {
    padding-right: 80px;
  }
}
.main-nav > ul,
.main-nav > h1 {
  display: inline-block;
  vertical-align: middle;
  line-height: 50px;
}
.main-nav li {
  display: inline-block;
}
.main-nav h1,
.main-nav .language-switcher {
  float: left;
}
.main-nav h1 {
  margin-left: 120px;
  font-weight: 700;
  font-size: 25px;
}
@media (max-width: 430px) {
  .main-nav h1 {
    margin-left: 0;
    padding-left: 10px;
    float: none;
  }
}
.main-nav .main-menu {
  font-size: 0;
}
@media (max-width: 1099px) {
  .main-nav .main-menu {
    display: none;
  }
}
.main-nav .main-menu li {
  font-size: 16px;
}
.main-nav .main-menu li a {
  display: block;
  padding: 0 10px;
}
.main-nav .main-menu li a:hover {
  background: lightgrey;
}
.main-nav .language-switcher {
  border-right: 1px solid #979797;
  background: white;
  height: 50px;
  overflow: hidden;
  position: absolute;
  z-index: 50;
  left: 0;
}
.main-nav .language-switcher .it,
.main-nav .language-switcher .de {
  display: none;
}
.main-nav .language-switcher li {
  display: none;
}
.main-nav .language-switcher li a {
  display: block;
  padding: 0 30px;
  text-indent: -9999px;
  background-position: center center;
  border-top: 1px solid #b1b1b1;
  width: 100%;
  height: 100%;
}
.main-nav .language-switcher li a:hover {
  background-color: lightgrey !important;
}
.main-nav .language-switcher li.active {
  display: block;
}
.main-nav .language-switcher li.active a {
  border-top: 0;
}
.main-nav .language-switcher:hover {
  height: auto;
}
.main-nav .language-switcher:hover li {
  display: block;
}
.main-nav .language-switcher:hover .it,
.main-nav .language-switcher:hover .de {
  display: none;
}
.main-nav .support {
  position: absolute;
  top: 0;
  right: 20px;
}
@media (max-width: 1099px) {
  .main-nav .support {
    display: none;
  }
}
.main-nav .support li {
  border-left: 1px solid #979797;
  padding: 0 20px;
}
.main-nav .support li:first-of-type {
  position: relative;
}
.main-nav .support li:first-of-type::after {
  content: '';
  width: 80px;
  height: 80px;
  top: 20px;
  right: -40px;
  position: absolute;
  z-index: 5;
  border-radius: 50%;
  background: url('../img/halo_istra.png') no-repeat center center;
  background-size: contain;
}
.mobile-toggle {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  display: inline-block;
  width: 36px;
  height: 25px;
  position: absolute;
  top: 0;
  right: 20px;
  bottom: 0;
  margin: auto;
}
@media (min-width: 1100px) {
  .mobile-toggle {
    display: none;
  }
}
.mobile-toggle span {
  display: block;
  position: relative;
  height: 4px;
  background: black;
  margin-top: 6px;
  border-radius: 3px;
  top: 0;
  transition: 300ms;
}
.mobile-toggle span:first-of-type {
  margin-top: 0;
}
.mobile-toggle.active span:nth-of-type(1) {
  top: 10px;
  transform: rotate(45deg);
}
.mobile-toggle.active span:nth-of-type(2) {
  transform: scaleX(0);
}
.mobile-toggle.active span:nth-of-type(3) {
  top: -10px;
  transform: rotate(-45deg);
}
.mobile-menu-box {
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid #979797;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  background: white;
}
@media (min-width: 1100px) {
  .mobile-menu-box {
    display: none;
  }
}
@media (max-width: 1099px) {
  .mobile-menu-box {
    width: auto;
  }
}
@media (max-width: 39.9375rem) {
  .mobile-menu-box {
    width: 100%;
  }
}
.mobile-menu-box a {
  display: block;
  padding: 20px 30px;
}
.mobile-menu-box a:hover {
  background-color: lightgrey;
}
.mobile-menu-box.ng-animate {
  transition: 300ms;
  transform-origin: top right;
}
.mobile-menu-box.ng-animate.ng-hide-add {
  transform: scale(1);
}
.mobile-menu-box.ng-animate.ng-hide-add-active {
  transform: scale(0);
}
.mobile-menu-box.ng-animate.ng-hide-remove {
  transform: scale(0);
}
.mobile-menu-box.ng-animate.ng-hide-remove-active {
  transform: scale(1);
}
.mobile-menu li {
  display: block;
}
.main-nav .mobile-support {
  font-size: 0;
  position: static;
  width: 100%;
  border-top: 1px solid #979797;
}
@media (max-width: 1099px) {
  .main-nav .mobile-support {
    display: block;
  }
}
.main-nav .mobile-support li {
  font-size: 1rem;
  padding: 0;
}
@media (max-width: 39.9375rem) {
  .main-nav .mobile-support li {
    width: 50%;
  }
}
.main-nav .mobile-support li:first-of-type {
  border-left: 0;
}
@media (max-width: 440px) {
  .main-nav .mobile-support a {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.pagination {
  display: inline-block;
  vertical-align: middle;
  margin-right: 50px;
  padding: 30px 0;
  text-align: center;
}
@media (max-width: 59.9375rem) {
  .pagination {
    width: 100%;
    margin: 0;
  }
}
.pagination li {
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 50px;
  background: white;
  border: 1px solid #D8D8D8;
  color: #979797;
  border-radius: 50%;
  font-size: 24px;
  font-weight: 300;
  margin: 10px 5px;
}
@media (max-width: 39.9375rem) {
  .pagination li {
    display: none;
  }
}
.pagination li a {
  display: block;
  vertical-align: middle;
  line-height: 48px;
  text-align: center;
  height: 100%;
  width: 100%;
}
.pagination li.active {
  color: #4A90E2;
  border-color: #4A90E2;
}
.pagination li.disabled {
  opacity: 0.5;
}
.pagination li.disabled:hover {
  cursor: not-allowed;
}
.pagination li.prev,
.pagination li.next {
  position: relative;
}
@media (max-width: 39.9375rem) {
  .pagination li.prev,
  .pagination li.next {
    display: inline-block;
  }
}
.pagination li.prev > a::before,
.pagination li.next > a::before,
.pagination li.prev > a::after,
.pagination li.next > a::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
}
.pagination li.prev > a::before,
.pagination li.next > a::before {
  height: 22px;
  width: 22px;
}
.pagination li.prev > a::after,
.pagination li.next > a::after {
  height: 16px;
  width: 16px;
}
.pagination li.prev > a::after {
  border-left: 2px solid #979797;
  border-bottom: 2px solid #979797;
  transform: rotate(45deg);
  left: 6px;
}
.pagination li.prev:first-of-type > a::before {
  width: 2px;
  background: #979797;
  right: 15px;
}
.pagination li.prev:first-of-type > a::after {
  left: 20px;
}
.pagination li.next > a::after {
  border-top: 2px solid #979797;
  border-right: 2px solid #979797;
  transform: rotate(45deg);
  right: 6px;
}
.pagination li.next:last-of-type > a::before {
  width: 2px;
  background: #979797;
  left: 15px;
}
.pagination li.next:last-of-type > a::after {
  right: 20px;
}
.paginator-info {
  display: inline-block;
  vertical-align: middle;
  color: #B6B6B6;
  font-size: 16px;
}
@media (max-width: 59.9375rem) {
  .paginator-info {
    display: block;
    width: 100%;
    text-align: center;
  }
}
.paginator-info strong {
  color: black;
}
.wizard-canvas {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.header-background {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: url('../img//porer_header.jpg') no-repeat center center;
  background-size: cover;
  filter: blur(0);
  transition: 400ms;
}
.header-background.blur {
  filter: blur(5px);
}
.service-wizard {
  padding-top: 220px;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  color: white;
  height: 900px;
  text-align: center;
  position: relative;
}
@media (max-width: 39.9375rem) {
  .service-wizard {
    padding-top: 150px;
  }
}
@media (max-width: 450px) {
  .service-wizard {
    padding-top: 120px;
  }
}
.service-wizard h1 {
  font-size: 36px;
  line-height: 49px;
  font-weight: 400;
  text-shadow: 0 0 10px black;
  padding-left: 20px;
  padding-right: 20px;
}
.service-wizard form {
  width: auto;
}
.service-wizard-helper {
  position: relative;
  margin-bottom: 100px;
  padding-top: 60px;
}
.service-wizard-helper .action {
  background: #2D2D2D;
  background: linear-gradient(#626262, #000);
  border: 1px solid rgba(176, 239, 16, 0.7);
  color: white;
  padding: 20px 60px;
  border-radius: 4px;
  font-size: 30px;
  line-height: 37px;
  outline: none;
  transition: 300ms;
}
.service-wizard-helper .action:hover {
  cursor: pointer;
  background: #3a3a3a;
  background: linear-gradient(#6f6f6f, #0d0d0d);
}
.service-wizard-helper .action:active {
  transform: scale(0.95);
}
.service {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  margin-left: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  transition: 300ms;
  cursor: pointer;
}
.service:first-of-type {
  margin-left: 0;
}
.service.excursions {
  background: #00A553 url('../img/izleti.png') no-repeat center 25px;
  background-size: 80px;
}
.service.guides {
  background: #039ADC url('../img/vodic.png') no-repeat center 25px;
  background-size: 80px;
}
.service.accommodation {
  background: #B40276 url('../img/sleeps-light.png') no-repeat center 10px;
  background-size: 95px;
}
.service.rent-a-car {
  background: #F28331 url('../img/rentacar.png') no-repeat 35px 25px;
  background-size: 80px;
}
.service h2 {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 36px;
  left: 0;
  margin: auto;
  font-size: 16px;
  line-height: 22px;
}
.service:hover {
  transform: scale(1.1);
}
@media (max-width: 39.9375rem) {
  .service {
    width: 120px;
    height: 120px;
  }
  .service.excursions {
    background-size: 70px;
    background-position: 23px 20px;
  }
  .service.guides {
    background-size: 70px;
    background-position: center center;
  }
  .service.accommodation {
    background-size: 80px;
    background-position: center 15px;
  }
  .service h2 {
    bottom: -30px;
  }
}
@media (max-width: 460px) {
  .service.accommodation {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }
}
.service-description {
  font-size: 20px;
  line-height: 30px;
  background: rgba(216, 216, 216, 0.5);
  border-radius: 40px;
  display: inline-block;
  padding: 4px 25px;
  transition: 600ms;
  box-shadow: 0 1px 3px black;
}
.service-description.excursions {
  background: #00A553;
}
.service-description.guides {
  background: #039ADC;
}
.service-description.accommodation {
  background: #B40276;
}
.service-description.rent-a-car {
  background: #F28331;
}
.header-content {
  position: absolute;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  color: white;
  background: rgba(71, 160, 12, 0.82);
  font-size: 26px;
  line-height: 36px;
  padding: 65px 0 75px;
}
.form-part-wrapper {
  border: 1px solid #979797;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  position: relative;
}
.form-part-wrapper .reset {
  position: absolute;
  top: -20px;
  right: -20px;
  left: auto;
  background: transparent url('../img/close.png') no-repeat center center;
  background-size: 40px;
  width: 40px;
  height: 40px;
  border: none;
  z-index: 10;
  outline: none;
  transition: 300ms;
  user-select: none;
}
.form-part-wrapper .reset:hover {
  color: white;
  cursor: pointer;
}
.form-part-wrapper .reset:active {
  transform: scale(0.95);
}
.form-part {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid #979797;
  padding: 40px 50px;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  color: black;
}
.form-part input,
.form-part textarea,
.form-part select,
.form-part .select {
  background: white;
  background: linear-gradient(white, #ddd);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12);
  border: 1px solid white;
}
.form-part input {
  display: inline-block;
  vertical-align: middle;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  height: 40px;
  outline: none;
  border-radius: 4px;
  padding: 0 12px;
  margin-right: 10px;
}
.form-part input[type=text] {
  width: 250px;
}
.form-part input[type=number] {
  width: 120px;
}
.form-part input[type=date] {
  width: 180px;
}
.form-part input[type=submit] {
  width: auto;
  background: #DE5304;
  background: linear-gradient(#ED5203, #D25305);
  font-size: 16px;
  border-radius: 4px;
  vertical-align: middle;
  border: 1px solid #E55A05;
  color: white;
  font-weight: 700;
  padding: 0 40px;
}
.form-part input[type=submit]:hover {
  cursor: pointer;
}
.form-part .btn {
  height: 40px;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  line-height: 40px;
  border: none;
  vertical-align: middle;
  cursor: pointer;
  display: inline-block;
}
.form-part .btn a {
  display: block;
  padding: 0 40px;
}
.form-part .btn.excursions {
  box-shadow: 0 2px 0 0 #007239;
  background: #00A553;
  background: linear-gradient(#00bf60, #008c46);
}
.form-part .btn.guides {
  box-shadow: 0 2px 0 0 #0277aa;
  background: #039ADC;
  background: linear-gradient(#03acf5, #0388c3);
}
.form-part .btn.accommodation {
  box-shadow: 0 2px 0 0 #820155;
  background: #B40276;
  background: linear-gradient(#cd0287, #9b0265);
}
.form-part .btn.rent-a-car {
  box-shadow: 0 2px 0 0 #e2680e;
  background: #F28331;
  background: linear-gradient(#f49149, #f07519);
}
.form-part .btn[disabled] {
  background: grey;
  box-shadow: 0 2px 0 0 darkgray;
}
.form-part .select {
  display: inline-block;
  overflow: hidden;
  height: 40px;
  width: 160px;
  border-radius: 4px;
  margin-right: 10px;
  vertical-align: middle;
}
.form-part .select select {
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;
  background: transparent;
}
.form-part .select option {
  padding: 12px;
  line-height: 40px;
  color: black;
}
.form-part .form-separator {
  margin-right: 10px;
  color: black;
  font-weight: bold;
  font-size: 14px;
}
.form-part select,
.form-part input {
  font-size: 14px;
}
.form-part select::-webkit-input-placeholder,
.form-part input::-webkit-input-placeholder {
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}
.form-part select::-moz-placeholder,
.form-part input::-moz-placeholder {
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}
.form-part select:-moz-placeholder,
.form-part input:-moz-placeholder {
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}
.form-part select:-ms-input-placeholder,
.form-part input:-ms-input-placeholder {
  font-size: 14px;
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
}
.form-part .select-indicator::before {
  border-top-color: #979797;
}
.form-part .select-indicator::after {
  border-bottom-color: #979797;
}
.form-part .wizard-wide-input {
  width: 250px;
}
input[type="text"].wizard-pickadate {
  width: 158px;
}
.input-autocomplete {
  display: inline-block;
}
