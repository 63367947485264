* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    &::before {
        &:extend(*);
    }
    &::after {
        &:extend(*);
    }
}

body {
    font-family: @font-body;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
    &:hover {
        cursor: pointer;
    }
}

h1, h2, h3, h4, h5, h6, p {
    font-size: inherit;
    font-weight: inherit;
}

.clearfix {
    zoom: 1;
    &::before {
        content: '';
        display: table;
    }
    &::after {
        &:extend(.clearfix::before);
        clear: both;
    }
}

[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
    display: none !important;
}

.shell {
    max-width: 980px;
    height: 100%;
    margin: auto;
    .padding-horizontal(20px);
    @media (max-width: 767px) {
        .padding-horizontal(10px);
    }
    &.bare {
        .padding-horizontal(0); // test
    }
    &.wide {
        max-width: 1440px;
    }
    &.medium {
        max-width: 1220px;
    }
}

.list-container {
    position: relative;
    max-width: 1320px;
    margin: auto;
    padding: 0 20px;
}

.animate-transition {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    transition: 400ms;
    &.ng-animate.fade-transition {
        &.ng-hide-add {
            opacity: 1;
        }
        &.ng-hide-add-active {
            opacity: 0;
        }
        &.ng-hide-remove {
            opacity: 0;
        }
        &.ng-hide-remove-active {
            opacity: 1;
        }
    }
    &.ng-animate.slide-left-transition {
        &.ng-hide-add {
            opacity: 1;
            transform: translateX(0);
        }
        &.ng-hide-add-active {
            opacity: 0;
            transform: translateX(-100%);
        }
        &.ng-hide-remove {
            opacity: 0;
            transform: translateX(100%);
        }
        &.ng-hide-remove-active {
            opacity: 1;
            transform: translateX(0);
        }
    }
    &.ng-animate.slide-up-transition {
        &.ng-hide-add {
            opacity: 1;
            transform: translateY(0);
        }
        &.ng-hide-add-active {
            opacity: 0;
            transform: translateY(100%);
        }
        &.ng-hide-remove {
            opacity: 0;
            transform: translateY(100%);
        }
        &.ng-hide-remove-active {
            opacity: 1;
            transform: translateY(0);
        }
    }
}

.select {
    overflow-x: hidden;
    select {
        outline: none;
        width: calc(100% + 25px);
        border: 0;
        background: transparent;
        font-size: inherit;
        color: inherit;
        &:hover {
            cursor: pointer;
        }
    }
}

button {
    border: 0;
    background: transparent;
    &:hover {
        cursor: pointer;
    }
}