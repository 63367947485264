.size (@width, @height: @width) {
	width: @width;
	height: @height;
}

.padding-horizontal (@padding-left, @padding-right: @padding-left) {
	padding-left: @padding-left;
	padding-right: @padding-right;
}

.padding-vertical (@padding-top, @padding-bottom: @padding-top) {
	padding-top: @padding-top;
	padding-bottom: @padding-bottom;
}

.margin-horizontal (@margin-left, @margin-right: @margin-left) {
	margin-left: @margin-left;
	margin-right: @margin-right;
}

.margin-vertical (@margin-top, @margin-bottom: @margin-top) {
	margin-top: @margin-top;
	margin-bottom: @margin-bottom;
}

.absolutely-centered (@top: 0; @right: 0; @bottom: 0; @left: 0) {
	position: absolute;
	top: @top;
	right: @right;
	bottom: @bottom;
	left: @left;
	margin: auto;
}

.user-select(@what) {
    -moz-user-select: @what;
    -webkit-user-select: @what;
    -ms-user-select: @what;
}

.placeholder-style (@font-size: inherit; @font-family: inherit; @font-weight: inherit; @color: inherit) {
	&::-webkit-input-placeholder {
		font-size: @font-size;
		font-family: @font-family;
		font-weight: @font-weight;
		color: @color;
	}
	&::-moz-placeholder {
		font-size: @font-size;
		font-family: @font-family;
		font-weight: @font-weight;
		color: @color;
	}
	&:-moz-placeholder {
		font-size: @font-size;
		font-family: @font-family;
		font-weight: @font-weight;
		color: @color;
	}
	&:-ms-input-placeholder {
		font-size: @font-size;
		font-family: @font-family;
		font-weight: @font-weight;
		color: @color;
	}
}