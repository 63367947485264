.accomodation-detail-content {
    //margin: 20px 20px 0;
    margin-top: 40px;
}

.accommodation-detail-content-wrapper {
    @media @sm {
        margin: -20px;
        width: 100vw;
    }
}

.accomodation-info-top-wrapper {
    margin-left: auto;
    margin-right: auto;
}

.info-top-hotel-wrapper {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    border-bottom: @border-dashed;
}

.info-top-hotel {
    padding-right: 20px;
    padding-bottom: 20px;
    border-right: @border-dashed;
    h2 {
        font-size: 24px;
        margin-bottom: 5px;
    }
    p {
        font-size: 18px;
    }
}

.accommodation-description-text {
    max-width: 60%;
    vertical-align: top;
    padding: 15px 35px 55px;
    text-align: center;
    //display: block;
    position: relative;
    color: #9C9C9C;
    font: 100 20px @font-body;
    strong {
        font-weight: 400;
        color: black;
    }
    a {
        display: block;
        font-size: 16px;
        color: @color-accommodation;
        font-weight: 300;
        margin-top: 20px;
    }
    @media @md-sm {
        display: block;
        max-width: none;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
    }
}

.accomodation-detail-wrapper {
    margin-bottom: 30px;
    padding-bottom: 20px;
    background-color: #fff;
    border: @border-solid;
    .accomodation-detail-unit {
        border-bottom: @border-dashed;

        .accomodation-detail-unit-left {
            padding: 1%;
            color: @gray;
            p {
                font-size: 1.1rem;
                line-height: 1.2em;
            }
            p.first {
                font-size: 1.3rem;
                font-weight: 700;
                color: @gray-base;
                margin-bottom: 2%;
            }
        }

        .accomodation-detail-unit-right {
            padding: 1%;
            text-align: center;
            p {
                font-size: 2rem;
                margin-bottom: 3%;
            }
        }
    }

    .accomodation-match-list-wrapper {
        @media @lg {
            padding: 40px;
        }
        @media @md {
            padding: 30px;
        }
        @media @sm {
            padding: 20px;
        }
    }

}

.accommodation-detail-content {
    line-height: 150%;
    @media @lg {
        padding: 50px;
    }
    @media @md {
        padding: 30px;
    }
    @media @sm {
        padding: 20px;
    }
}

.accomodation-detail-tabs {
    top: 0;
    li {
        @media @lg {
            margin-bottom: 5px;
        }
        a {
            color: @gray-light;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 16px;
            padding: 15px 10px;
            text-transform: uppercase;
            font-weight: bold;
            background-color: #FBFAFA;
            display: block;
            border: @border-solid;
            border-radius: 0 4px 4px 0;
            position: relative;
            @media @lg {
                left: -1px;
            }
            @media @md {
                border-left: @border-solid;
                border-bottom: @border-solid;
                border-radius: 4px 4px 0 0;
                position: relative;
                top: 1px;
                text-align: center;
            }
            @media @sm {
                text-align: center;
                border-radius: 0;
                border-top: none;
                border-bottom: none;
                &:first-of-type {
                    border-top: @border-solid;
                }
            }
        }
        .accomodation-tab-active {
            color: black;
            background-color: #ffffff;
            @media @lg {
                border-left: 1px solid transparent;
            }
            @media @md {
                border-bottom: 1px solid transparent;
            }
        }
    }
}

.unit-name {
    font-size: 21px;
    font-weight: bold;
    margin-bottom: 30px;
    &.overlaid {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px 20px;
        color: white;
        background: fade(black, 30%);
        font-weight: 300;
        margin: 0;
        text-shadow: 0 0 3px #000;
        z-index: 1;
    }
}

.unit-basic-info {
    position: relative;
    li {
        line-height: 1.5em;
    }
    .unit-occupancy {
        margin-bottom: 0;
    }
}

.unit-panel-info {
    padding: 30px;
    font-size: 14px;
}

.unit-panel-info-expanded {
    padding: 0 30px 0 0;
    @media @md-sm {
        padding-right: 0;
    }
}

.unit-details {
    h3 {
        margin: 0 0 30px;
        font-weight: bold;
    }
}

.unit-description {
    padding-right: 50px;
    line-height: 150%;
}

.unit-amenities-list {
    line-height: 150%;
    padding-right: 50px;
    @media @sm {
        margin-top: 50px;
    }
}

.unit-packages {
    margin-bottom: 40px;
    h3 {
        //font-weight: normal;
    }
}

.unit-images {
    position: relative;
    z-index: 0;
    margin-bottom: 40px;
    img {
        width: 100%;
    }
    .fancybox {
        display: none;
        &:first-child {
            display: block;
        }
    }
}

.unit-plan {
    border: 1px solid @color-text;
    //border-top: 0;
    border-radius: 4px;
    padding: 30px;
    background: white;
    margin-top: 40px;
    &:first-of-type {
        margin-top: 0;
        border-top: 1px solid @color-text;
    }
    .reservation {
        border: 0;
        max-width: 300px;
        color: white;
        border-radius: 3px;
        font-size: 14px;
        background: @color-accommodation; // IE9 fallback
        background: @gradient-accommodation;
        a {
            display: block;
            cursor: pointer;
            width: 100%;
            padding: 15px 10px;
        }
    }
    .policy-content {
        display: none;
    }
    .package-content {
        display: none;
    }
    .plan-policy-info {
        display: block;
        margin-top: 0.5em;
        font-size: 11px;
        color: #b40276;
//        &:before {
//            content: '';
//            display: inline-block;
//            vertical-align: middle;
//            height: 28px;
//            width: 28px;
//            border: 1px solid #939393;
//            border-radius: 50%;
//            margin-right: 5px;
//            margin-top: -4px;
//            background: url(../img/info-icon.png) center center/6px no-repeat;
//        }
    }
    .info-icon-grey {
        position: relative;
        display: inline-block;
        width: 15px;
        height: 15px;
        &:after {
            position: absolute;
            top: 1px;
            content: '';
            width: 18px;
            height: 18px;
            display: block;
            background: url("../img/info-icon-grey.png") no-repeat center center;
        }
    }
}

.unit-plans-preview li {
    line-height: 200%;
    font-size: 14px;
}

.unit-panel-contracted:nth-of-type(even) {
    background-color: @gray-lighter;
}

.unit-panel-contracted:nth-of-type(odd) {
    background-color: #fff;
}

.unit-panel {
    margin: 50px 0;
    &:first-of-type {
        margin-top: 0;
    }
}

.unit-panel-contracted {
    margin: 0;
    border: 1px solid @color-text;
    border-top: none;
    &:first-of-type {
        border-top: 1px solid @color-text;
    }
    .unit-plan {
        background: transparent;
        border-radius: 0;
        border: none;
        border-left: 1px dashed @color-text;
        @media @sm {
            border-left: none;
        }
        @media @lg-md {
            &.noavailability {
                height: 200px;
                padding-top: 75px;
            }
            .reservation {
                max-width: none;
            }
        }
    }
    @media @md {
        .unit-plans {
            display: inline-block;
            width: 50%;
        }
        .unit-panel-info {
            display: inline-block;
            width: 50%;
        }
    }
}

.unit-panel-expanded {
    .unit-plans {
        @media @md {
            font-size: 0;
            > * {
                font-size: 1rem;
            }
        }
    }
    .unit-plan {
        @media @md {
            display: inline-block;
            width: 49%;
            margin-top: 20px;
            &:nth-of-type(2n + 1) {
                margin-right: 1%;
            }
            &:nth-of-type(2n + 2) {
                margin-left: 1%;
            }
        }
        @media @sm {
            padding: 0;
            border: none;
        }
    }
    .unit-occupancy {
        @media @md-sm {
            margin-top: 40px;
        }
    }
}

.unit-panel-expanded ~ .unit-panel {
    border-top: 1px solid @color-text;
    border-bottom: 0;
    &:last-of-type {
        border-bottom: 1px solid @color-text;
    }
}

.plan-board {
    color: @color-accommodation;
    font-weight: bold;
}

.plan-name {
    font-weight: bold;
    color: #b40276;
}

.plan-currency {
    font-size: 26px;
    margin: 0 0 0.5em 0;
}

.plan-price-type {
    display: inline-block;
    font-size: 13px;
    margin-top: 1em;
}

.accommodation-property-images {
    margin-top: 50px;
    li {
        padding: 20px;
        overflow: hidden;
        height: 200px;
        img {
            width: 100%;
        }
    }
}

.unit-occupancy {
    margin-bottom: 40px;
    li {
        font-size: 14px;
        line-height: 200%;
    }
}

.accommodation-detail-map {
    max-height: 320px;
    overflow: hidden;
    img {
        width: 100%;
    }
}

.accommodation-detail-map-info {
    padding: 20px 40px;
}

.slide-animation {
    &.ng-animate {
        transition: 200ms height, 200ms opacity;
        overflow: hidden;
        &.ng-hide-add {
            height: 0;
        }
        &.ng-hide-add-active {
            height: 0;
        }
        &.ng-hide-remove {
            height: 0;
            opacity: 0;
        }
        &.ng-hide-remove-active {
            height: 500px;
            opacity: 1;
        }
    }
}

.hotel-image-block {
    margin-bottom: 30px;
    position: relative;
    top: 10px;
    img {
        width: 100%;
        margin-bottom: 10px;
    }
    .fancybox {
        display: none;
        &:first-child {
            display: block;
        }
    }
    i {
        cursor: pointer;
    }
    .gallery-count {
        top: 30px;
    }
}

.hotel-info-block {
    @media @lg {
        padding-left: 40px;
    }
}

.hotel-title-block,
.page-title-block {
    padding-bottom: 20px;
    line-height: 200%;
    h2 {
        font-size: 30px;
        font-weight: bold;
    }
}

.hotel-title-block {
    border-bottom: 1px dashed black;
}

.page-title-block {
    display: inline-block;
    padding: 0 0 0 0;
    margin: 0 0 20px 0;
    max-width: 30%;
    vertical-align: top;
    @media @md-sm {
        display: block;
        max-width: none;
    }
}

.page-title-block-info {
    border-right: 1px dashed @color-text;
    display: inline-block;
    padding-right: 20px;
    @media @md-sm {
        padding-right: 0;
        border-right: none;
    }
}

.hotel-description-block {
    h3 {
        font-weight: bold;
        margin: 35px 0 30px;
    }
}

.hotel-map {
    width: 100%;
    @media @lg {
        height: 50vw;
    }
    @media @md {
        height: 60vw;
    }
    @media @sm {
        height: 70vw;
    }
}

.detail-container {
    max-width: 1220px;
    margin: auto;
}

.fancybox-inner .policy-content {
    padding: 20px;
    UL {
        LI {
            font-size: 14px;
            border-top: 1px solid #C2C1C1;
            padding: 10px 0;
        }
    }
}

.fancybox-inner .package-content {
    padding: 20px;
    box-sizing: border-box;
    .package-image-slider {
        float: left;
        max-width: 425px;
        //max-height: 320px;
        margin-right: 35px;
        //overflow: hidden;
        .slider-item {
            width: 425px;
            //max-height: 320px;
            img {
                width: 100%;
            }
        }
    }
    H2 {
        font-size: 26px;
        line-height: 120%;
        margin-bottom: 50px;
    }
    H3 {
        font-size: 21px;
        font-weight: 700;
        margin-bottom: 20px;
    }
    .package-basic-info {
        vertical-align: top;
        P {
            line-height: 25px;
        }
    }
    .package-additional-info {
        padding-top: 30px;
    }
    .package-description {
        vertical-align: top;
        display: inline-block;
        box-sizing: border-box;
        width: 49%;
        padding-right: 30px;
    }
    .package-policy {
        vertical-align: top;
        display: inline-block;
        box-sizing: border-box;
        width: 49%;
        padding-left: 30px;
        UL {
            LI {
                font-size: 14px;
                border-top: 1px solid #C2C1C1;
                padding: 10px 0;
            }
        }
    }
}

.gallery-count {
    position: absolute;
    display: inline-block;
    top: 70px;
    left: 0;
    padding: 10px 15px 10px 40px;
    font-size: 14px;
    color: white;
    line-height: 100%;
    background: url("../img/gallery-icon.png") no-repeat 10px 49% #b40276;
}
