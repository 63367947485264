.footer-info {
    padding: 45px 45px 65px;
    @media @md-sm {
        padding-left: 20px;
        padding-right: 20px;
        > * {
            padding-top: 10px;
            &:first-child {
                padding-top: 0;
            }
            line-height: 15px;
        }
    }
    background: #F7F5F6;
    box-shadow: 0 -2px 4px fade(black, 26%);
    p {
        font-size: 9px;
    }
}

.footer-extras {
    padding: 40px 80px 40px;
    @media (min-width: 960px) and (max-width: 1200px) {
        padding-left: 40px;
        padding-right: 40px;
    }
    @media @md-sm {
        padding-left: 20px;
        padding-right: 20px;
    }
    font-size: 14px;
    color: #737373;
    border-top: 1px solid @color-text;
    line-height: 22px;
    background: #FAFAFA;
    h3 {
        font-weight: 500;
        color: black;
        padding-bottom: 20px;
    }
    h4 {
        font-size: 11px;
        font-weight: bold;
        color: #737373;
    }
    ul {
        margin-bottom: 20px;
        li {
            font-size: 11px;
        }
    }
}

.region {
    vertical-align: top;
    h3 {
        padding-bottom: 0;
    }
    width: auto;
    padding-right: 40px;
    &:last-child {
        padding-right: 0;
    }
    li {
        font-weight: 300;
        &::before {
          content: " - ";
        }
    }
}

//.footer-sub {
//    height: 160px;
//    background: #F7F5F6;
//    font-size: 9px;
//    line-height: 12px;
//    padding: 45px;
//}