.contact-form-wrapper{
    display:inline-block;
    width:73%;
    padding:0px 50px 50px 0px;
    box-sizing:border-box;
    -moz-box-sizing:border-box;

    h2{
        font-weight: 700;
        font-size: 21px;
        margin-bottom: 0px;
        display:block;
        padding:0px 0px 20px 0;
    }

    .form-field{
        display:block;
        margin-bottom:10px;

        label{
            width:33%;
            display:inline-block;
            vertical-align:top;
        }

        &>div{
            display:inline-block;
            width:66%;

            input, textarea{
                width:100%;
                padding:4px 12px;
                margin-bottom:0;
            }
        }

        .form-field-captcha{
            position:relative;
            padding: 0 0 0 95px;
            box-sizing:border-box;
            -moze-box-sizing:border-box;

            img{
                position:absolute;
                top:-3px;
                left:0;
            }

        }

    }

    button[type="submit"]{
        padding: 10px 30px;
        color: #FFF;
        background-color: #00A553;
        font-size: 16px;
        border-radius: 3px;

        &:hover{
            opacity:0.8;
        }
    }

    .errorlist{
        width: 65%;
        margin-left: 34%;
        background: #eee;
        border: 1px solid #ccc;
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        padding: 5px 20px;
        color: red;
        font-size: 12px;
        margin-bottom: 20px;
    }

}

@media all and(max-width:1360px){
    .contact-form-wrapper{
        width:100%;
        padding:50px 0px;
    }
}

@media all and(max-width:500px){
    .contact-form-wrapper{
        .form-field{
            margin-bottom:15px;
            label{
                width:100%;
            }
            &>div{
                width:100%;
            }
        }
    }
}

.contact-form-sent-wrapper{
    display:inline-block;
    width:100%;
    text-align:center;
    padding:80px 0 150px;

    h1{
        font-size: 30px;
        margin-bottom: 30px;
    }
}