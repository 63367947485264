.home-title {
    text-align: center;
    font-weight: 300;
    padding: 60px 0;
    h2 {
        font-size: 46px;
        line-height: 56px;
        color: #ED6806;
        margin-bottom: 20px;
    }
    p {
        font-size: 24px;
        line-height: 29px;
        color: #9C9C9C;
    }
}

.home-services {

}

.panel {
    margin-top: 55px;
    &:first-of-type {
        margin-top: 0;
    }
    h2 {
        font-size: 28px;
        line-height: 44px;
        color: white;
        font-weight: 300;
        text-align: center;
        position: relative;
    }
    .panel-icon {
        position: relative;
        padding-top: 130px;
        padding-bottom: 30px;
        text-align: center;
    }
    .expand-mobile-panel {
        @media @lg-md {
            display: none;
        }
        @media @sm {
            width: auto;
            font-size: 16px;
            border-radius: 4px;
            vertical-align: middle;
            border: 1px solid fade(#fff, 50%);
            background: fade(#fff, 15%);
            color: white;
            font-weight: 700;
            padding: 0.3em 1em;
            margin-top: 30px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .panel-form {
        height: 100%;
        color: white;
        @media @md-sm {
            padding-bottom: 30px;
        }
    }
    .panel-promo-box {
        height: 240px;
        padding: 20px 10px;
        text-align: right;
    }
    &.excursions {
        background: @color-excursions; // IE9 fallback
        background: @gradient-excursions;
        .panel-icon {
            background: url('@{img}izleti.png') no-repeat center 25px;
            background-size: 80px;
        }
        .panel-promo-box {
            background: url('@{img}excursions-promo.jpg') no-repeat center center;
            background-size: cover;
        }
    }
    &.guides {
        background: @color-guides; // IE9 fallback
        background: @gradient-guides;
        .panel-icon {
            background: url('@{img}vodic.png') no-repeat center 25px;
            background-size: 80px;
        }
        .panel-promo-box {
            background: url('@{img}guides-promo.jpg') no-repeat center center;
            background-size: cover;
        }
    }
    &.accommodation {
        background: @color-accommodation; // IE9 fallback
        background: @gradient-accommodation;
        .panel-icon {
            background: url('@{img}sleeps-light.png') no-repeat center 25px;
            background-size: 95px;
        }
        .panel-promo-box {
            background: url('@{img}accommodation-promo.jpg') no-repeat center center;
            background-size: cover;
        }
    }
    &.rent-a-car {
        background: @color-rent-a-car; // IE9 fallback
        background: @gradient-rent-a-car;
        .panel-icon {
            background: url('@{img}rentacar.png') no-repeat center 35px;
            background-size: 80px;
        }
        .panel-promo-box {
            background: url('@{img}rent-a-car-promo.jpg') no-repeat center center;
            background-size: cover;
        }
    }
    input {
        display: inline-block;
        vertical-align: middle;
        font-family: @font-body;
        height: 40px;
        border: 1px solid fade(#fff, 50%);
        background: fade(#fff, 15%);
        outline: none;
        border-radius: 4px;
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
        &[type=submit] {
            width: auto;
            font-size: 16px;
            border-radius: 4px;
            vertical-align: middle;
            border: 1px solid fade(#fff, 50%);
            background: fade(#fff, 15%);
            color: white;
            font-weight: 700;
            padding: 0 20px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .checkbox {
        padding: 10px 12px;
        font-size: 14px;
//        border-left: 1px dashed #D8A4C0;
//        &:first-of-type {
//            border-left: 0;
//        }
        label {
            &::before {
                background: fade(#fff, 15%);
                border: 1px solid fade(#fff, 50%);
                color: white;
                border-radius: 4px;
                .size(22px);
            }
            &:hover {
                cursor: pointer;
            }
        }
//        input[type="checkbox"]:checked + label::before {
//            background: url('@{img}check.png') no-repeat center center / 80%;
//        }
    }
    .select {
        display: inline-block;
        background: fade(#fff, 15%);
        border: 1px solid fade(#fff, 50%);
        overflow: hidden;
        height: 40px;
        width: 100%;
        border-radius: 4px;
        vertical-align: middle;
        padding-left: 5px;
        select {
            background: transparent;
            border: 0;
            outline: none;
            height: 38px;
            color: white;
            option {
                color: black;
            }
        }
    }
    select, input {
        font-size: 14px;
        color: white;
        .placeholder-style(@font-size: 14px; @color: white);
    }
}

.destinations {
    .padding-vertical(55px);
    text-align: center;
    h2 {
        font-size: 36px;
        line-height: 44px;
        font-weight: 300;
        margin-bottom: 35px;
    }
    .home-slider {
        position: relative;
        .slider-wrap {
            //display: none;
        }
        .slider-item {
            background: #fff;
            border-radius: 3px;
            padding: 10px;
            border: 1px solid #979797;
            box-shadow: 0 0 4px rgba(0,0,0,.25);
            .card-content-container {
                font-size: 16px;
                font-weight: 700;
                text-align: center;
                padding-top: 10px;
            }
            A {
                color: black;
            }
            @media screen and (max-width: 767px){
                width: 44%;
                margin-bottom: 10px;
            }
            @media screen and (max-width: 480px) {
                width: 100%;
            }
        }
        .slider-img-container {
            position: relative;
            overflow: hidden;
            padding-top: 100%;
            .img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
        }

        .bx-prev,
        .bx-next {
            display: block;
            cursor: pointer;
            .size(80px);
            transition: 100ms;
            z-index: 10;
            &:hover {
                cursor: pointer;
            }
            &:active {
                transform: scale(0.95);
            }
        }
        .bx-prev {
            .absolutely-centered(@right: auto; @left: -50px);
            background: url('@{img}left.png') no-repeat center center;
            background-size: contain;
        }
        .bx-next {
            .absolutely-centered(@left: auto; @right: -50px);
            background: url('@{img}right.png') no-repeat center center;
            background-size: contain;

        }
    }
    > p {
        font-size: 18px;
        line-height: 22px;
        font-weight: 300;
        &:first-of-type {
            margin-top: 35px;
        }
    }
    a {
        color: #4A90E2;
    }
}

.select-indicator {
    position: relative;
    &::before,
    &::after {
        content: '';
        position: absolute;
        display: block;
        left: auto;
        right: 12px;
        margin: auto;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
    &::before {
        top: 55%;
        border-top: 6px solid fade(white, 50%);
    }
    &::after {
        bottom: 55%;
        border-bottom: 6px solid fade(white, 50%);
    }
}

.panel-form {
    padding: 15px 35px;
    @media @sm {
        padding-left: 20px;
        padding-right: 20px;
    }
    .grid {
        > [class*="col-"] {
            padding: 10px;
        }
    }
    &.home-form {
        @media @sm {
            display: none;
            //padding-bottom: 35px;
        }
    }
}

.picker__holder {
    min-width: 320px;
    left: 0;
}

.from-to-price,
.see-more {
    display: inline-block;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 14px;
}

.from-to-price {
    background: fade(white, 70%);
    text-shadow: 0 0 3px fade(black, 40%);
    > * {
        vertical-align: middle;
    }
    strong {
        font-size: 18px;
        margin-right: 3px;
    }
}

.see-more {
    color: white;
    padding: 10px 20px;
    &.excursions {
        background: @color-excursions;
    }
    &.guides {
        background: @color-guides;
    }
    &.accommodation {
        background: @color-accommodation;
    }
    &.rent-a-car {
        background: @color-rent-a-car;
    }
}

.panel {
    input{
        &.submit-button {
            border: 1px solid fade(white, 50%);
            box-shadow: 0 1px 2px fade(black, 64%);
            text-shadow: 0 2px 2px fade(black, 25%);
            height: 45px;
            font-weight: 400;
        }
        &.excursion-button {
            background: hue(@color-excursions); // IE9 fallback
            background: @gradient-excursions-button;
            .padding-horizontal(2.5em);
        }
        &.guides-button {
            background: hue(@color-guides); // IE9 fallback
            background: @gradient-guides-button;
            .padding-horizontal(2.5em);
        }
        &.accommodation-button {
            background: @color-accommodation; // IE9 fallback
            background: @gradient-accommodation-button;
            //width: 100%;
        }
    }
}

.form-fields {
    margin-bottom: 20px;
}

//.number-row {
//    > *[class*="col-"]:first-of-type {
//        padding-left: 0;
//    }
//}

.panel .form-dropdown {
    overflow: visible;
    padding-left: 0;
    text-align: left;
    font-weight: 400;
    > a {
        display: block;
        height: 100%;
        line-height: 38px;
        padding-left: 10px;
        font-size: 14px;
        cursor: pointer;
        .user-select(none);
    }
}


.checkboxes-dropdown {
    position: absolute;
    z-index: 3;
    background: #F6F6F6;
    color: lighten(black, 30%);
    border: 1px solid fade(black, 20%);
    box-shadow: 0 4px 8px fade(black, 20%);
    border-radius: 4px;
    width: 100%;
    min-width: 200px;
    height: auto;
    left: 0;
    top: 100%;
    li {
        display: block;
    }
}

.panel .checkboxes-dropdown {
    .checkbox label {
        &::before {
            border-color: lighten(black, 30%);
        }
    }
    .checkbox input[type=checkbox]:checked+label::before {
        background-image: url('@{img}check-dark.png');
    }
}

.destination-gallery {
    padding: 0 50px;
    max-width: 1540px;
    margin: auto;
}

.panel-showcase {
    margin: -20px -10px;
    height: 240px;
    padding: 20px 10px;
    position: relative;
}

.panel-showcase-info {
    background: fade(white, 70%);
    padding: 20px;
    font-size: 22px;
    margin: -20px -10px 20px;
    h1 {
        font-weight: bold;
    }
}

.showcase-see-more {
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.beta-badge {
    display: inline-block;
    float: left;
    margin-top: 10px;
    margin-left: 5px;
    background-color: #60BE02;
    border-radius: 5px;
    color: white;
    text-transform: lowercase;
    font-size: small;
    font-weight: 400;
    padding: 1px 5px;
}