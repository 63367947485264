.content-area {
    padding: 50px 0;
    .content {
        max-width: 940px;
        margin: 0 auto;
    }
    P {
        font-size: 18px;
        margin-bottom: 1em;
        line-height: 150%;
    }
    H1 {
        font-size: 36px;
        line-height: 44px;
        font-weight: 300;
        margin-bottom: 35px;
        text-align: center;
    }
    H2 {
        font-weight: 700;
        font-size: 26px;
        margin-bottom: 20px;
    }
    H3, H4, H5 {
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 20px;
    }
    UL, OL {
        margin-left: 40px;
        margin-bottom: 1em;
        line-height: 150%;
        font-size: 18px;
        LI {
            padding: 5px 0;
            position: relative;
            &::before {
                content: ' - ';
                position: absolute;
                left: -20px;
            }
        }
    }
}