// class name, property, value

.generate-responsive-class(valign-top, vertical-align, top);
.generate-responsive-class(valign-middle, vertical-align, middle);
.generate-responsive-class(valign-bottom, vertical-align, bottom);
.generate-responsive-class(text-left, text-align, left);
.generate-responsive-class(text-center, text-align, center);
.generate-responsive-class(text-right, text-align, right);
.generate-responsive-class(push, float, right);
.generate-responsive-class(pull, float, left);
.generate-responsive-class(hidden, display, none);

.generate-responsive-class(@name; @property; @value) {
    .@{name} {
        @media @lg-md {
            @{property}: @value;
        }
    }

    .@{name}-md-sm {
        @media @md-sm {
            @{property}: @value;
        }
    }

    .@{name}-lg{
        @media @lg {
            @{property}: @value;
        }
    }

    .@{name}-md{
        @media @md {
            @{property}: @value;
        }
    }

    .@{name}-sm{
        @media @sm {
            @{property}: @value;
        }
    }
}