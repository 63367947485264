.animation-fade {
    &.ng-animate {
        transition: 300ms opacity;
        &.ng-hide-add {
            opacity: 1;
        }
        &.ng-hide-add-active {
            opacity: 0;
        }
        &.ng-hide-remove {
            opacity: 0;
        }
        &.ng-hide-remove-active {
            opacity: 1;
        }
    }
}

.animation-scale-top {
    &.ng-animate {
        transition: 200ms ease-in;
        transform-origin: top center;
        &.ng-hide-add {
            transform: scaleY(1);
        }
        &.ng-hide-add-active {
            transform: scaleY(0);
        }
        &.ng-hide-remove {
            transform: scaleY(0);
        }
        &.ng-hide-remove-active {
            transform: scaleY(1);
        }
    }
}