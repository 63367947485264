.form {
    font-size: 40px;
    textarea {
        resize: vertical;
    }
    input,
    textarea {
        @placeholder-color: fade(black, 50%);
        .placeholder-style(@color: @placeholder-color);
    }
    input,
    textarea,
    select {
        //		outline: none;
        padding: 0.5em;
        font-size: 1rem;
    }
    input,
    textarea,
    .select {
        background: #fff;
        border: 1px solid black;
        width: 100%;
        font-family: inherit;
        font-size: inherit;
    }
    input[type='checkbox'],
    input[type='radio'] {
        height: 1em;
        width: 1em;
        margin-right: 0.5em;
        display: inline-block;
        vertical-align: middle;
        overflow-x: hidden;

    }
    input[type='radio'] {
        border-radius: 50%;
        position: relative;
        &::before {
            border-radius: 50%;
        }
        &:checked::before {
            background: radial-gradient(black 50%, white 50%);
        }
    }
}

.checkbox {
    display: inline-block;
    vertical-align: middle;
    overflow-x: hidden;
    position: relative;
    label {
        .user-select(none);
        &:hover {
            cursor: pointer;
        }
        &::before {
            content: '';
            margin-right: 0.5em;
            height: 1.2em;
            width: 1.2em;
            background: white;
            border: 1px solid black;
            display: inline-block;
            vertical-align: middle;
            line-height: 1.2em;
            font-size: 1.2em;
            text-align: center;
            position: relative;
            top: -0.1em;
        }
    }
    input[type="checkbox"] {
        display: none;
        &:checked + label::before {
            background-image: url('@{img}check.png');
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 15px;
        }
    }
}

.radio {
    &::before {
        &:extend(.checkbox::before);
        border-radius: 50%;
    }
}

.select {
    overflow-x: hidden;
    position: relative;
    select {
        //		outline: none;
        background: transparent;
        border: 0;
        width: calc(100% + 20px + 1em);
        z-index: 1;
        position: relative;
        height: 2rem;
        padding: 0.5em;
        -webkit-appearance: none;
    }
}

.checkboxes > * {
    padding: 10px 0;
}

.pickadate-container {
    position: relative;
    display: inline-block;
    vertical-align: inherit;
}

.input-autocomplete {
    position: relative;
    .loader {
        padding-right: 30px;
    }
}

.input-suggestions {
    position: absolute;
    left: 0;
    right: 10px;
    top: 100%;
    background: white;
    color: black;
    z-index: 20;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    box-shadow: 0 1px 3px fade(black, 20%);
    border: 1px solid white;
    text-align: left;
    li {
        padding: 10px;
        font-size: 14px;
        border-top: 1px solid fade(black, 20%);
        transition: 200ms background-color;
        &:first-of-type {
            border-top: none;
        }
        &:hover {
            cursor: pointer;
            background-color: fade(black, 10%);
        }
    }
}

.location-querying {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 20px;
    margin: auto;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    border: 3px solid white;
    border-top: 3px solid transparent;
    animation: loading 1s ease-in-out infinite;
}


.select {
    .pseudo-select {
        display: none;
        width: 100%;
    }
    .select2-container .select2-selection--single {
        color: white;
        background: transparent;
        height: 40px;
        line-height: 40px;
        border: none;
        outline: none;
    }
    .select2-container .select2-selection--single .select2-selection__arrow {
        display: none;
    }
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        line-height: 38px;
        color: white;
        font-size: 14px;
        text-align: left;
        font-weight: 400;
    }
    &.wizard .select2-container--default .select2-selection--single .select2-selection__rendered {
        color: black;
    }
    .select2-container .select2-selection__placeholder {
        color: white;
        font-weight: 400;
    }
    &.wizard .select2-container .select2-selection__placeholder {
        color: black;
    }
}

.select2-dropdown {
    background-color: #F6F6F6;
}

.select2-container--open .select2-dropdown--below {
    box-shadow: 0 10px 10px fade(black, 25%);
    left: -5px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
    padding: 5px;
    font-size: 14px;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: @color-accommodation;
}

.select2-results__option {
    font-size: 14px;
    padding-left: 15px;
    &[id*='all'],
    &[id*='region'] {
        font-weight: 700;
    }
    &[id*='zip'] {
        padding-left: 35px;
    }
}