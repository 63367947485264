@larger-than-mobile-menu: ~'(min-width: 1100px)';
@smaller-than-mobile-menu: ~'(max-width: 1099px)';


.main-nav {
    font-weight: 300;
    border-bottom: 1px solid #979797;
    font-family: @font-body;
    padding-left: 15px;
    @media @larger-than-mobile-menu {
        padding-right: 280px;
    }
    @media @smaller-than-mobile-menu {
        padding-right: 80px;
    }
    z-index: 40;
    text-align: center;
    height: 51px; // 50 + 1px border-bottom
    width: 100%;
    background: white;
    position: relative;
    > ul, > h1 {
        display: inline-block;
        vertical-align: middle;
        line-height: 50px;
    }
    li {
        display: inline-block;
    }
    h1, .language-switcher {
        float: left;
    }
    h1 {
        margin-left: 120px;
        font-weight: 700;
        font-size: 25px;
        @media (max-width: 430px) {
            margin-left: 0;
            padding-left: 10px;
            float: none;
        }
    }
    .main-menu {
        @media @smaller-than-mobile-menu {
            display: none;
        }
        font-size: 0;
        li {
            font-size: 16px;
            a {
                display: block;
                padding: 0 10px;
                &:hover {
                    background: lightgrey;
                }
            }
        }
    }
    .language-switcher {
        border-right: 1px solid #979797;
        background: white;
        height: 50px;
        overflow: hidden;
        position: absolute;
        z-index: 50;
        left: 0;
        .it, .de {
            display: none;
        }
        //&::after {
        //    content: '\25BC';
        //    position: absolute;
        //    font-size: 10px;
        //    top: 0;
        //    right: 5px;
        //    color: #979797;
        //}


        li {
            display: none;
            a {
                display: block;
                padding: 0 30px;
                text-indent: -9999px;
                background-position: center center;
                border-top: 1px solid #b1b1b1;
                .size(100%);
                &:hover {
                    background-color: lightgrey !important;
                }
            }
            &.active {
                display: block;
                a {
                    border-top: 0;
                }
            }
        }
        &:hover {
            height: auto;
            li {
                display: block;
            }
            .it, .de {
            display: none;
        }
        }
    }
    .support {
        @media @smaller-than-mobile-menu {
            display: none;
        }
        position: absolute;
        top: 0;
        right: 20px;
        li {
            border-left: 1px solid #979797;
            padding: 0 20px;
            &:first-of-type {
                position: relative;
                &::after {
                    content: '';
                    .size(80px);
                    top: 20px;
                    right: -40px;
                    position: absolute;
                    z-index: 5;
                    border-radius: 50%;
                    background: url('@{img}halo_istra.png') no-repeat center center;
                    background-size: contain;
                }
            }
        }
    }
}

.mobile-toggle {
    .user-select(none);
    @media @larger-than-mobile-menu {
        display: none;
    }
    cursor: pointer;
    display: inline-block;
    width: 36px;
    height: 25px;
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    margin: auto;
    span {
        display: block;
        position: relative;
        height: 4px;
        background: black;
        margin-top: 6px;
        border-radius: 3px;
        top: 0;
        transition: 300ms;
        &:first-of-type {
            margin-top: 0;
        }
    }
    &.active {
        span:nth-of-type(1) {
            top: 10px;
            transform: rotate(45deg);
        }
        span:nth-of-type(2) {
            transform: scaleX(0);
        }
        span:nth-of-type(3) {
            top: -10px;
            transform: rotate(-45deg);
        }
    }
}

.mobile-menu-box {
    @media @larger-than-mobile-menu {
        display: none;
    }
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid #979797;
    box-shadow: 0 0 10px fade(black, 25%);
    background: white;
    @media @smaller-than-mobile-menu {
        width: auto;
    }
    @media @sm {
        width: 100%;
    }
    a {
        display: block;
        padding: 20px 30px;
        &:hover {
            background-color: lightgrey;
        }
    }
    &.ng-animate {
        transition: 300ms;
        transform-origin: top right;
        &.ng-hide-add {
            transform: scale(1);
        }
        &.ng-hide-add-active {
            transform: scale(0);
        }
        &.ng-hide-remove {
            transform: scale(0);
        }
        &.ng-hide-remove-active {
            transform: scale(1);
        }
    }
}

.mobile-menu  li {
    display: block;
}

.main-nav .mobile-support {
    @media @smaller-than-mobile-menu {
        display: block;
    }
    font-size: 0;
    position: static;
    width: 100%;
    border-top: 1px solid #979797;

    li {
        @media @sm {
            width: 50%;
        }
        font-size: 1rem;
        padding: 0;
        &:first-of-type {
            border-left: 0;
        }
    }

    @media (max-width: 440px) {
        a {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}