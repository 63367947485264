.content-area.iframe-layout {
    UL.destination-container-wrap {
        text-align: center;
        margin-left: inherit;
        margin-bottom: inherit;
        line-height: inherit;
        font-size: inherit;
        LI {
            padding: 10px 15px;
            &::before {
                display: none;
            }
        }
    }
}

.destination-container-wrap .card-content-container h3 {
    margin-bottom: 0;
}

.destination-list-title {
    padding-left: 20px;
    padding-right: 20px;
    @media @md-sm {
        margin-top: 30px;
    }
}

.destination-container {
    padding: 10px 15px;
    @media (min-width: 1600px) {
        width: 20%;
    }
    @media (max-width: 499px) {
        width: 100%;
    }
}

.destination-detail-header {
    background: #6FADF4;
    @media @md-sm {
        margin-left: -20px;
        margin-right: -20px;
        width: 100vw;
    }
}

.destination-image-container {
    overflow: hidden;
    .img {
        height: 330px;
    }
}

.destination-title-container {
    padding: 30px;
    position: relative;
    @media @lg {
        top: -40px;
    }
    @media @md-sm {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.destination-name {
    font-weight: 100;
    font-size: 80px;
    color: white;
    @media @sm {
        font-size: 48px;
    }
}

.about-destination {
    padding: 30px 0;
    font-size: 18px;
    line-height: 25px;
    h2 {
        font-weight: 700;
        font-size: 26px;
        margin-bottom: 20px;
    }
    p {
        margin-bottom: 1em;
    }
}

.iframe-layout.destination-detail {
    .sidebar {
        @media (max-width: 1084px) {
            padding-left: 0;
            max-width: none;
            display: block;
        }
    }

    .sidebar-block {
        font-size: 0;
        @media (max-width: 1084px) and (min-width: 561px) {
            margin: 0 -10px;
        }
        > * {
            font-size: 1rem;
        }
        > h2 {
            margin-left: 10px;
        }
        > a {
            @media (max-width: 1084px) {
                padding: 0 10px;
                width: 25%;
                display: inline-block;
            }
            @media (max-width: 935px) {
                width: 50%;
            }
            @media (max-width: 560px) {
                width: 100%;
            }
        }
    }
}

.destination-detail {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    > * {
        text-align: start;
    }

    .destination-content {
        @media (min-width: 1085px) {
            width: calc(100% - 350px);
        }
        width: 100%;
        text-align: left;
    }

    .destination-card {
        background: white;
        width: 100%;
        border-radius: 3px;
        padding: 10px;
        border: 1px solid @color-text;
        box-shadow: 0 0 4px fade(black, 25%);
        margin-bottom: 15px;
    }

    .card-img-container {
        position: relative;
        padding-top: 80%;
        overflow: hidden;
        .img {
            margin: auto;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
        }
    }

    .card-content-container {
        padding-top: 10px;
        h3 {
            font-size: 16px;
            font-weight: bold;
        }
        h4 {
            font-size: 14px;
        }
    }

    .card-content-additional-info {
        color: #4A90E2;
        font-weight: 300;
        text-align: center;
        border-top: 1px solid @color-text;
        .margin-horizontal(-10px);
        .padding-horizontal(10px);
        margin-top: 10px;
        padding-top: 10px;
        .additional-info {
            margin-top: 0;
        }
        .promo {
            padding-top: 5px;
            float: right;
            font-size: 14px;
            color: black;
        }
    }

    .sidebar-block {
        padding-top: 20px;
        padding-bottom: 20px;
        &:first-of-type {
            padding-top: 0;
        }
        h2 {
            font-weight: bold;
            font-size: 21px;
            margin-bottom: 20px;
        }
    }

    .link-block {
        text-align: center;
        a {
            font-size: 16px;
            font-weight: 300;
            color: #4A90E2;
        }
    }
}