.pagination {
    display: inline-block;
    vertical-align: middle;
    margin-right: 50px;
    padding: 30px 0;
    text-align: center;
    @media @md-sm {
        width: 100%;
        margin: 0;
    }
    li {
        display: inline-block;
        vertical-align: top;
        .size(50px);
        background: white;
        @media @sm {
            display: none;
        };
        border: 1px solid #D8D8D8;
        color: #979797;
        border-radius: 50%;
        font-size: 24px;
        font-weight: 300;
        margin: 10px 5px;
        a {
            display: block;
            vertical-align: middle;
            line-height: 48px;
            text-align: center;
            height: 100%;
            width: 100%;
        }
        &.active {
            color: @color-link;
            border-color: @color-link;
        }
        &.disabled {
            opacity: 0.5;
            &:hover {
                cursor: not-allowed;
            }
        }

        @control-width: 2px;
        &.prev,
        &.next {
            @media @sm {
                display: inline-block;
            }
            position: relative;
            > a::before,
            > a::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                display: block;
            }
            > a::before {
                height: 22px;
                width: 22px;
            }
            > a::after {
                height: 16px;
                width: 16px;
            }
        }
        &.prev {
            > a::after {
                border-left: @control-width solid @color-text;
                border-bottom: @control-width solid @color-text;
                transform: rotate(45deg);
                left: 6px;
            }
            &:first-of-type {
                > a::before {
                    width: @control-width;
                    background: @color-text;
                    right: 15px;
                }
                > a::after {
                    left: 20px;
                }
            }
        }
        &.next {
            > a::after {
                border-top: @control-width solid @color-text;
                border-right: @control-width solid @color-text;
                transform: rotate(45deg);
                right: 6px;
            }
            &:last-of-type {
                > a::before {
                    width: @control-width;
                    background: @color-text;
                    left: 15px;
                }
                > a::after {
                    right: 20px;
                }
            }
        }
    }
}

.paginator-info {
    display: inline-block;
    vertical-align: middle;
    @media @md-sm {
        display: block;
        width: 100%;
        text-align: center;
    }
    color: #B6B6B6;
    font-size: 16px;
    strong {
        color: black;
    }
}