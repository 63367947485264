.wizard-canvas {
    //background: black;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.header-background {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url('@{img}/porer_header.jpg') no-repeat center center;
    background-size: cover;
    filter: blur(0);
    transition: 400ms;
    &.blur {
        filter: blur(5px);
        //background: url('@{img}/header_jama_blur.jpg') no-repeat center center;
        //background-size: cover;
    }
}

.service-wizard {
    padding-top: 220px;
    @media @sm {
        padding-top: 150px;
    }
    @media (max-width: 450px) {
        padding-top: 120px;
    }
    font-family: @font-body;
    color: white;
    height: 900px;
    text-align: center;
    position: relative;
    h1 {
        font-size: 36px;
        line-height: 49px;
        font-weight: 400;
        text-shadow: 0 0 10px black;
        padding-left: 20px;
        padding-right: 20px;
    }
    form {
        width: auto;
    }
}

.service-wizard-helper {
    position: relative;
    margin-bottom: 100px;
    padding-top: 60px;
    .action {
        background: #2D2D2D; // IE9 fallback
        background: linear-gradient(#626262, #000);
        border: 1px solid fade(#B0EF10, 70%);
        color: white;
        padding: 20px 60px;
        border-radius: 4px;
        font-size: 30px;
        line-height: 37px;
        outline: none;
        transition: 300ms;
        &:hover {
            cursor: pointer;
            background: lighten(#2D2D2D, 5%); // IE9 fallback
            background: linear-gradient(lighten(#626262, 5%), lighten(#000, 5%));
        }
        &:active {
            transform: scale(0.95);
        }
    }
}

.service {
    .size(160px);
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-left: 20px;
    box-shadow: 0 4px 10px fade(black, 50%);
    transition: 300ms;
    cursor: pointer;
    &:first-of-type {
        margin-left: 0;
    }
    &.excursions {
        background: @color-excursions url('@{img}izleti.png') no-repeat center 25px;
        background-size: 80px;
    }
    &.guides {
        background: @color-guides url('@{img}vodic.png') no-repeat center 25px;
        background-size: 80px;
    }
    &.accommodation {
        background: @color-accommodation url('@{img}sleeps-light.png') no-repeat center 10px;
        background-size: 95px;
    }
    &.rent-a-car {
        background: @color-rent-a-car url('@{img}rentacar.png') no-repeat 35px 25px;
        background-size: 80px;
    }
    h2 {
        .absolutely-centered(@top: auto; @bottom: 36px);
        font-size: 16px;
        line-height: 22px;
    }
    &:hover {
        transform: scale(1.1);
    }
    @media @sm {
        .size(120px);
        &.excursions {
            background-size: 70px;
            background-position: 23px 20px;
        }
        &.guides {
            background-size: 70px;
            background-position: center center;
        }
        &.accommodation {
            background-size: 80px;
            background-position: center 15px;
        }
        h2 {
            bottom: -30px;
        }
    }
    @media (max-width: 460px) {
        &.accommodation {
            display: block;
            margin-left: auto;
            margin-right: auto;
            margin-top: 40px;
        }
    }
}

.service-description {
    font-size: 20px;
    line-height: 30px;
    background: fade(#D8D8D8, 50%);
    border-radius: 40px;
    display: inline-block;
    padding: 4px 25px;
    transition: 600ms;
    box-shadow: 0 1px 3px black;
    &.excursions {
        background: @color-excursions;
    }
    &.guides {
        background: @color-guides;
    }
    &.accommodation {
        background: @color-accommodation;
    }
    &.rent-a-car {
        background: @color-rent-a-car;
    }
}

.header-content {
    .absolutely-centered(@top: auto);
    color: white;
    background: fade(#47A00C, 82%);
    font-size: 26px;
    line-height: 36px;
    padding: 65px 0 75px;
}

.form-part-wrapper {
    border: 1px solid #979797;
    padding: 10px;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    .reset {
        position: absolute;
        top: -20px;
        right: -20px;
        left: auto;
        background: transparent url('@{img}close.png') no-repeat center center;
        background-size: 40px;
        .size(40px);
        border: none;
        z-index: 10;
        outline: none;
        transition: 300ms;
        user-select: none;
        &:hover {
            color: white;
            cursor: pointer;
        }
        &:active {
            transform: scale(0.95);
        }
    }
}

.form-part {
    border-radius: 5px;
    background: fade(#FFFFFF, 60%);
    border: 1px solid #979797;
    padding: 40px 50px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    color: black;
    input,
    textarea,
    select,
    .select {
        background: white; // IE9 fallback
        background: linear-gradient(white, #ddd);
        box-shadow: 0 1px 2px fade(black, 12%);
        border: 1px solid white;
    }
    input {
        display: inline-block;
        vertical-align: middle;
        font-family: @font-body;
        height: 40px;
        outline: none;
        border-radius: 4px;
        padding: 0 12px;
        margin-right: 10px;
        &[type=text] {
            width: 250px;
        }
        &[type=number] {
            width: 120px;
        }
        &[type=date] {
            width: 180px;
        }
        &[type=submit] {
            width: auto;
            background: #DE5304; // IE9 fallback
            background: linear-gradient(#ED5203, #D25305);
            font-size: 16px;
            border-radius: 4px;
            vertical-align: middle;
            border: 1px solid #E55A05;
            color: white;
            font-weight: 700;
            padding: 0 40px;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .btn {
        height: 40px;
        color: white;
        border-radius: 4px;
        font-size: 16px;
        line-height: 40px;
        border: none;
        vertical-align: middle;
        cursor: pointer;
        display: inline-block;
        a {
            display: block;
            padding: 0 40px;
        }
        &.excursions {
            box-shadow: 0 2px 0 0 darken(@color-excursions, 10%);
            background: @color-excursions; // IE9 fallback
            background: @gradient-excursions;
        }
        &.guides {
            box-shadow: 0 2px 0 0 darken(@color-guides, 10%);
            background: @color-guides; // IE 9 fallback
            background: @gradient-guides;
        }
        &.accommodation {
            box-shadow: 0 2px 0 0 darken(@color-accommodation, 10%);
            background: @color-accommodation; // IE 9 fallback
            background: @gradient-accommodation;
        }
        &.rent-a-car {
            box-shadow: 0 2px 0 0 darken(@color-rent-a-car, 10%);
            background: @color-rent-a-car; // IE 9 fallback
            background: @gradient-rent-a-car;
        }
        &[disabled] {
            background: grey;
            box-shadow: 0 2px 0 0 darkgray;
        }
    }
    .select {
        display: inline-block;
        overflow: hidden;
        height: 40px;
        width: 160px;
        border-radius: 4px;
        margin-right: 10px;
        vertical-align: middle;
        select {
            .size(100%);
            border: 0;
            outline: none;
            background: transparent;
        }
        option {
            padding: 12px;
            line-height: 40px;
            color: black;
        }
    }
    .form-separator {
        margin-right: 10px;
        color: black;
        font-weight: bold;
        font-size: 14px;
    }
    select, input {
        font-size: 14px;
        .placeholder-style(@font-size: 14px);
    }
    .select-indicator {
        &::before {
            border-top-color: @color-text;
        }
        &::after {
            border-bottom-color: @color-text;
        }
    }
    .wizard-wide-input {
        width: 250px;
    }
}

input[type="text"].wizard-pickadate {
    width: 158px;
}

.input-autocomplete {
    display: inline-block;
}